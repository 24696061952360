import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

export const UsersList = () => {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    const response = await axios.get("https://mysro.in/get/users");
    setUsers(response.data);
  };
  const deleteUsers = async (userId) => {
    await axios.delete(`https://mysro.in/delete/users/${userId}`);
    getUsers();
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div>
      <h1>Users</h1>
      <h2>List of Users</h2>
      <Link to="/users/add">Add User</Link>
      <table className="table">
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.uuid}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Link to={`/users/edit/${user.uuid}`}>Edit</Link>
              </td>
              <td>
                <Button
                  className="button"
                  onClick={() => deleteUsers(user.uuid)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
