import React, { useState } from "react";
import { Panel } from "primereact/panel";
import { Fieldset } from "primereact/fieldset";
import { Dialog } from "primereact/dialog";

export const PreviewAgreement = ({ previewData }) => {
  const data = {
    name: "test",
    email: "test2@gmail.com",
    mobile: "9876543201",
    state: "Tamil Nadu",
    city: "bb",
    stamp: 100,
    iam: "Landlord",
    landlordDetails: [
      {
        name: "test",
        parentname: "trweds",
        address: "no 2, jehdj street",
        city: "chennai",
        state: "tamil nadu",
        pincode: "600009",
        mobile: "9876543210",
        email: "test@gmail.com",
        panid: "",
        aadhaar: "",
      },
      {
        docNumber: "",
        docYear: "",
        subRegOffice: "",
        regAddress: "",
        name: "test 2",
        parentname: "retwef",
        address: "no address",
        city: "no city",
        state: "no state",
        pincode: "600008",
        mobile: "9876543210",
        email: "test1@gmail.com",
      },
    ],
    tenantDetails: [
      {
        name: "test data",
        parentname: "father",
        address: "address",
        city: "city",
        state: "state",
        pincode: "987654",
        mobile: "9876543210",
        email: "test2@gmail.com",
        panid: "",
        aadhaar: "",
      },
    ],
    addItemList: [
      {
        name: "Fans",
        quantity: 0,
      },
      {
        name: "Chair",
        quantity: 2,
      },
      {
        name: "Sofa",
        quantity: 1,
      },
      {
        name: "Table",
        quantity: 1,
      },
      {
        name: "Bed",
        quantity: 0,
      },
      {
        name: "Wooden",
        quantity: 0,
      },
      {
        name: "cupboards",
        quantity: 0,
      },
      {
        name: "Dining Table",
        quantity: 0,
      },
      {
        name: "Air Conditioners",
        quantity: 0,
      },
      {
        name: "Air Coolers",
        quantity: 0,
      },
      {
        name: "Geysers",
        quantity: 0,
      },
      {
        name: "TV",
        quantity: 0,
      },
      {
        name: "Fridge",
        quantity: 0,
      },
      {
        name: "Oven",
        quantity: 0,
      },
      {
        name: "Washing Machine",
        quantity: 0,
      },
      {
        name: "Study Table",
        quantity: 0,
      },
      {
        name: "Gas stove",
        quantity: 0,
      },
      {
        name: "Modular Kitchen",
        quantity: 0,
      },
      {
        name: "Chimney",
        quantity: 0,
      },
    ],
    floor: "3",
    bhk: "2 BHK",
    houseno: "10/1",
    propertyaddress: "test address",
    propertycity: "city",
    propertystate: "state",
    propertypincode: "908070",
    agreementdate: "2023-04-23T18:30:00.000Z",
    agreementperiod: "10 Months",
    monthlyrent: "9000",
    addChargesIncluded: true,
    rentdate: "4",
    securityamount: "90000",
    noticeperiod: "3",
    rentincrement: true,
    otherclauses: "no ",
    increasepercentage: "10",
    furnishing: "Fully Furnished",
  };
  const {
    city,
    tenantDetails,
    landlordDetails,
    propertyaddress,
    propertycity,
    propertystate,
    propertypincode,
    floor,
    bhk,
    agreementdate,
    monthlyrent,
    securityamount,
    noticeperiod,
    increasepercentage,
    addItemList,
    rentdate,
    agreementperiod,
    furnishing,
  } = data;

  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="container">
        <p>Please check your details carefully</p>
        <div className="grid m-0">
          <div className="col-8">
            <div className="card">
              <Fieldset
                className="parentFieldset"
                legend={
                  <div className="flex align-items-center text-primary">
                    <span className="pi pi-user mr-2"></span>
                    <span className="font-bold text-lg">Landlord Details</span>
                  </div>
                }
              >
                <div className="grid m-0">
                  <div className="col-9">
                    <h4 className="m-0">{landlordDetails[0].name}</h4>
                    <p className="m-0">{landlordDetails[0].address}</p>
                    <p className="m-0">{landlordDetails[0].city}</p>
                  </div>
                  <div className="col-3">
                    <p className="m-0 text-right">Change >></p>
                  </div>
                </div>
              </Fieldset>
            </div>
            <div className="card">
              <Fieldset
                className="parentFieldset"
                legend={
                  <div className="flex align-items-center text-primary">
                    <span className="pi pi-user mr-2"></span>
                    <span className="font-bold text-lg">Tenant Details</span>
                  </div>
                }
              >
                <div className="grid m-0">
                  <div className="col-9">
                    <h4 className="m-0">{tenantDetails[0].name}</h4>
                    <p className="m-0">{tenantDetails[0].address}</p>
                    <p className="m-0">{tenantDetails[0].city}</p>
                  </div>
                  <div className="col-3">
                    <p className="m-0 text-right">Change >></p>
                  </div>
                </div>
              </Fieldset>
            </div>
            <div className="card">
              <Fieldset
                className="parentFieldset"
                legend={
                  <div className="flex align-items-center text-primary">
                    <span className="pi pi-user mr-2"></span>
                    <span className="font-bold text-lg">Property Details</span>
                  </div>
                }
              >
                <div className="grid m-0">
                  <div className="col-9">
                    <h4 className="m-0">
                      {propertyaddress}, {propertycity}, {propertystate},{" "}
                      {propertypincode}
                    </h4>
                    <p className="m-0"></p>
                    <p className="m-0">
                      Floor: {floor} • {bhk}
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="m-0 text-right">Change >></p>
                  </div>
                </div>
              </Fieldset>
            </div>
            <div className="card">
              <Fieldset
                className="parentFieldset"
                legend={
                  <div className="flex align-items-center text-primary">
                    <span className="pi pi-user mr-2"></span>
                    <span className="font-bold text-lg">Contract Details</span>
                  </div>
                }
              >
                <div className="grid m-0">
                  <div className="col-9">
                    <h4 className="m-0">{agreementdate}</h4>
                    <p className="m-0">
                      Rent: ₹{monthlyrent} • Deposit: ₹{securityamount}
                    </p>
                    <p className="m-0">
                      (Every Month) • Notice: {noticeperiod} Month Rent
                      Increment: {increasepercentage}% After Duration Is Over
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="m-0 text-right">Change >></p>
                  </div>
                </div>
              </Fieldset>
            </div>
            <div className="card">
              <Fieldset
                className="parentFieldset"
                legend={
                  <div className="flex align-items-center text-primary">
                    <span className="pi pi-user mr-2"></span>
                    <span className="font-bold text-lg">Item List</span>
                  </div>
                }
              >
                <div className="grid m-0">
                  <div className="col-9">
                    {addItemList.map((data, index) => {
                      if (data.quantity > 0) {
                        return (
                          <p>
                            {data.name} - {data.quantity}
                          </p>
                        );
                      } else {
                        return;
                      }
                    })}
                  </div>
                  <div className="col-3">
                    <p className="m-0 text-right">Change >></p>
                  </div>
                </div>
              </Fieldset>
            </div>
          </div>
          <div className="col-4">
            <div className="bgGray h-100 p-4 rightPreviewBox">
              <div className="flex justifyContentSpace">
                <span>Total Amount</span>
                <span>699</span>
              </div>
              <div
                className="previewAgre flex"
                onClick={() => setVisible(true)}
              >
                <img src="https://c.housingcdn.com/chimera/s/client/common/assets/previewAgreementDesktop.05634896.svg" />
                <div className="">
                  <h4 className="m-0">Preview Agreement</h4>
                  <p className="m-0">
                    See how your agreement is going to look like in reality
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Agreement Preview"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <img src="https://housing-ca.s3-ap-southeast-1.amazonaws.com/housing-edge/assets/SJ105AB349.png" />
          <p className="m-0 text-center">
            <u>
              <b>LEASE DEED</b>
            </u>
          </p>
          <div>
            <p></p>
            This RENTAL AGREEMENT is executed on{" "}
            <span className="highlightContent">{agreementdate}</span> at{" "}
            <span className="highlightContent">{city}</span> by and between:
            <p>
              <span className="highlightContent">
                {landlordDetails[0].name}
              </span>
              , <br />
              S/o or D/o.{" "}
              <span className="highlightContent">
                {landlordDetails[0].parentname}
              </span>
              ,
              <span className="highlightContent">
                {landlordDetails[0].address}
              </span>
              ,
              <span className="highlightContent">
                {landlordDetails[0].city}
              </span>
              ,<br />
              <span className="highlightContent">
                {landlordDetails[0].state}
              </span>
              ,
              <span className="highlightContent">
                {landlordDetails[0].pincode}
              </span>
            </p>
            {landlordDetails.length > 1 &&
              landlordDetails.map((data, index) => {
                return (
                  <p>
                    Co-owner:
                    <span className="highlightContent">{data.name}</span>,{" "}
                    <br />
                    S/o or D/o.{" "}
                    <span className="highlightContent">
                      {data.parentname}
                    </span>, <br />
                    <span className="highlightContent">
                      {data.address}
                    </span>,{" "}
                    <span className="highlightContent">{data.city}</span>,
                    <br />
                    <span className="highlightContent">{data.state}</span>,{" "}
                    <span className="highlightContent">{data.pincode}</span>
                  </p>
                );
              })}
            <br />
            <p>
              (hereinafter jointly and severally called the “OWNER”, which
              expression shall include their heirs, legal representatives,
              successors and assigns) of the ONE PART:
            </p>
            <p>AND, in favour of: </p>
            {tenantDetails.map((data, index) => {
              return (
                <p>
                  Tenant {index + 1}:{" "}
                  <span className="highlightContent">{data.name}</span>, <br />
                  S/o or D/o.{" "}
                  <span className="highlightContent">{data.parentname}</span>,
                  <br />{" "}
                  <span className="highlightContent">{data.address}</span>,{" "}
                  <span className="highlightContent">{data.city}</span>,
                  <br />
                  <span className="highlightContent">{data.state}</span>,{" "}
                  <span className="highlightContent">{data.pincode}</span>
                </p>
              );
            })}
            <p>
              (hereinafter called the “TENANT”, which expression shall include
              its legal representatives, successors and assigns) of the OTHER
              PART.
            </p>
            <p>
              WHEREAS the Owner is the absolute owner of the property situated
              at{" "}
              <span className="highlightContent">
                {propertyaddress}, {propertycity}, {propertystate},{" "}
                {propertypincode}
              </span>{" "}
              as detailed in Annexure-I, hereinafter referred to as "Demised
              Premises".
            </p>
            <p>
              Whereas on the request of the Tenant, the Owner have agreed to let
              out the said demised premises to the Tenant, and the Tenant has
              agreed to take it on rent w.e.f{" "}
              <span className="highlightContent">{agreementdate}</span> for its
              bonafide residential use. Whereas the Owner has represented that
              the said demised premises is free from all encumbrances and the
              Owner has a clean and unrestricted right to the said demised
              premises. Whereas the Owner and Tenant both represented that they
              are legally competent to enter into this Rental Agreement on the
              term's conditions contained herein.
            </p>
            <p>
              <b>NOW THIS DEED WITNESSETH AS FOLLOWS:</b>
            </p>
            <ol>
              <li>
                The rent in respect of the “Demised Premises” shall commence
                from <span className="highlightContent">{agreementdate}</span>{" "}
                and shall be valid till 11 months (Expiry Date of Agreement).
                Thereafter, the same may be extended further on mutual consent
                of both the parties.
              </li>
              <li>
                That the Tenant shall pay to the Owner a monthly rent of Rs.
                <span className="highlightContent">{monthlyrent} /-</span>,
                excluding electricity and water bill. The rent shall be paid on
                or before <span className="highlightContent">{rentdate}</span>{" "}
                day of each month without fail. Rent is inclusive of a monthly
                maintenance charge towards the maintenance of Generator &
                Elevator, Salaries towards guards, Charges for Electricity
                Maintenance for Common Areas, Charges towards cleaning of Common
                Areas and towards maintaining the lawn in the society. In case
                of TDS deduction, the tenant shall furnish the TDS certificate
                to the Owner(s) at the end of each calendar quarter well within
                time so as to enable the Owner(s) to file his income tax return
                within the stipulated timeframe. Each of the parties will bear
                the consequences for any non-compliance on account of the tax
                liability of its part.
              </li>
              <li>
                That during the Rent period, in addition to the rental amount
                payable to the Owner, the Tenant shall pay for the use of
                electricity and water as per bills received from the authorities
                concerned directly. In the unlikely instance that the
                connection/s for electricity or water is disconnected due to
                non-payment or negligence of the Tenant, the charges to
                restoring such connections shall be borne fully by the Tenant
                and if not paid the same can be deducted from the security
                deposit provided to the Owner(s).
              </li>
              <li>
                The Owner(s) will ensure that all outstanding bills/ charges on
                the above said demised premises on account of electricity,
                water, and any other incidentals prior to the start of lease
                from <span className="highlightContent">{agreementdate}</span>{" "}
                are settled and paid. Any payment on account of the above
                pertaining to the period before the start of lease w.e.f.{" "}
                <span className="highlightContent">{agreementdate}</span> will
                be settled by the Owner(s).
              </li>
              <li>
                The Tenant will pay to the Owner an interest-free refundable
                security deposit of Rs.{" "}
                <span className="highlightContent">{securityamount}/-</span> at
                the time of signing the Rent Agreement. The said amount of the
                Security deposit shall be refunded by the Owner to the Tenant at
                the time at the time of vacating the demised premises after
                deducting any outstanding rent, electricity and water charges,
                sewerage and maintenance charges, bills, painting charges etc.,
                if any, which are payable by the Tenant at the time of vacating
                the demised premises. Owner(s) shall have the right to adjust
                all the dues including but not limited to rent, maintenance,
                electricity, water, sewerage, etc. of the notice period from the
                Refundable Security deposit.
              </li>
              <li>
                That all the sanitary, electrical and other fittings and
                fixtures and appliances in the premises shall be handed over
                from the Owner to the Tenant in good working condition.
              </li>
              <li>
                That in case any damage is caused by the Tenant to the aforesaid
                premises, fixtures, fittings, etc. (except normal wear and
                tear), the Tenant shall be liable to make good the same to
                ensure that those is restored in the same condition as they were
                at the time of signing of this agreement other than the changes
                made by the Tenant with the consent of the Owner(s). In case of
                Tenant fails to do so, Owner(s) shall be entitled to deduct the
                costs of doing the same from the interest free security deposit.
              </li>
              <li>
                That after the expiry of the monthly rent shall be increased at
                the escalation of{" "}
                <span className="highlightContent">{increasepercentage} %</span>{" "}
                or at mutually agreed by all the parties at the time of renewal
                in the discussion as per prevailing market conditions.
              </li>
              <li>
                The Tenant shall not sublet, assign or part with the demised
                premises in whole or part thereof to any person in any
                circumstances whatsoever and the same shall be used for the
                bonafide residential purposes only. That the demised premises
                shall be used by the Tenant in a cordial and civilized manner
                without causing any nuisance or disturbance to the other
                occupants of the building complex/Society. Tenant shall use the
                demised premises for its bonafide legal purposes and shall not
                do or cause any actions or activities of illegal, immoral,
                unsocial nature in the said demised premises and will not create
                any nuisance to the neighbourhood in any manner whatsoever.
              </li>
              <li>
                That the day-to-day minor repairs will be the responsibility for
                the Tenant at his/her own expense. However, any structural or
                major repairs, if so required, shall be carried out by the
                Owner.
              </li>
              <li>
                That no structural additions or alterations shall be made by the
                Tenant in the premises without the prior written consent of the
                Owner but the Tenant can install furnishings in the space
                provided and other electrical gadgets and make such changes for
                the purposes as may be necessary, at his own cost. On
                termination of the tenancy or earlier, the Tenant will be
                entitled to remove such equipment and restore the changes made,
                if any, to the original state.
              </li>
              <li>
                That the Owner shall hold the right to visit in person or
                through his authorized agent(s), servants, workmen etc., to
                enter upon the demised premises for inspection or to carry out
                repairs / construction, as and when required.
              </li>
              <li>
                That the Tenant shall comply with all the rules and regulations
                of the local authority applicable to the demised premises. The
                premises will be used only for residential purposes of its
                employees, families and guests. Tenant shall have no right to
                sub-let the whole or part of demised premises to any other
                person or entity at any time.
              </li>
              <li>
                That the Owner shall pay for all taxes/cesses levied on the
                premises by the local or government authorities in the way of
                property tax for the premises and so on. Further, any other
                payment in the nature of subscription or periodical fee to the
                welfare association shall be paid by the Owner.
              </li>
              <li>
                That the Owner will keep the Tenant free and harmless from any
                claims, proceedings, demands, or actions by others with respect
                to quiet possession of the premises.
              </li>
              <li>
                In case of defaults by tenant in payment of rent for any month
                or commits any breach of any of the terms and conditions of this
                deed, the Owner(s) shall be entitled to get back the possession
                of the demised premises after providing reasonable notice to the
                tenant. In such case, notice to the tenant shall be given by
                each of Owner(s).
              </li>
              <li>
                That this Rent Agreement can be terminated before the expiry of
                this tenancy period by serving{" "}
                <span className="highlightContent">{noticeperiod}</span> prior
                notice in writing by either party.
              </li>
              <li>
                The Tenant shall maintain the Demised Premises in good and
                tenable condition and all the minor repairs such as leakage in
                the sanitary fittings, water taps and electrical usage etc.
                shall be carried out by the Tenant. That it shall be the
                responsibility of the Tenant to hand over the vacant and
                peaceful possession of the demised premises on expiry of the
                Rent period, or on its early termination, as stated hereinabove
                in the same condition subject to natural wear and tear.
              </li>
              <li>
                That in case, where the Premises are not vacated by the Tenant,
                at the termination of the Rent period, the Tenant will pay
                damages calculated at two times the rent for any period of
                occupation commencing from the expiry of the Rent period. The
                payment of damages as aforesaid will not preclude the Owner from
                initiating legal proceedings against the Tenant for recovering
                possession of premises or for any other purpose.
              </li>
              <li>
                That both the parties shall observe and adhere to the terms and
                conditions contained hereinabove.
              </li>
              <li>
                That the Tenant and Owners represent and warrant that they are
                fully empowered and competent to make this Rent. That both the
                parties have read over and understood all the contents of this
                agreement and have signed the same without any force or pressure
                from any side.
              </li>
              <li>
                In case of any dispute to this agreement and the clauses herein,
                the same will be settled in the civil courts within the
                jurisdiction at <span className="highlightContent">{city}</span>
                .
              </li>
              <li>
                Without any prejudice to a Party's other rights and claims under
                this agreement or otherwise, if one party breaches any of its
                representations, obligations, warranties, covenants or
                undertakings or violates any provision hereunder, it shall
                indemnify and keep the other Party and/or service providers
                harmless against all direct damages and costs suffered or borne
                by it/them thereby including but not limited to costs incurred
                in defending all claims/actions, or proceedings that may arise
                or may be otherwise necessary to ensure exclusive, quiet and
                peaceful access, occupation and use of the Demised Premises in
                accordance with this Deed. Without prejudice to other rights
                enjoyed by either Party (non- defaulting Party) under the Deed
                and Applicable Laws, the other Party (Defaulting Party) shall be
                responsible for and will indemnify against all claims, demands,
                suits, proceedings, judgments, direct damage, and relevant costs
                that the non-defaulting Party may suffer or incur in connection
                with loss of life and/or personal injury to the occupants of the
                Demised Premises and/or damage to the Building if the same arise
                from any wrongful/negligent act or omission of the defaulting
                Party.
              </li>
              <li>
                Force Majeure: If the whole or any part of the said Premises
                shall at any time during the term of the lease be destroyed or
                damaged due to any force majeure circumstances including storm,
                tempest, flood, Act of God, an act of terrorism, war or any
                other irresistible force or the Tenant is deprived of the use of
                the said Premises for reasons not attributable to the Tenant,
                the Owner(s) hereby undertakes to restore the said Premises as
                expeditiously as possible or, as the case may be, to remove the
                impediment in its use and occupation as expeditiously as
                possible. Notwithstanding the foregoing, upon the happening of
                any such event as aforesaid, the Tenant shall not be liable to
                pay Rent during the period the Tenant is deprived of the use of
                the said Premises or any part thereof. The Tenant shall also
                have the option to terminate the agreement after the event by
                giving one month's notice and without payment of any rent in
                lieu thereof and without incurring any liability to pay any
                other amount whatsoever to the Owner(s).
              </li>
              <li>
                Notice: Any notice or communication to be addressed by one party
                to the other shall be in writing and shall be served at the
                addresses as given hereinabove by registered post with
                acknowledgement or at such other addresses as may be notified in
                writing by one party to another. Any change in such address
                shall be promptly notified to the other party in writing.
              </li>
            </ol>
            <p className="text-center">
              <b>ANNEXURE-I</b>
            </p>
            <table>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Demised Premises</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Rent Amount including maintenance</td>
                  <td>
                    Rs. <span className="highlightContent">{monthlyrent}</span>{" "}
                    per month from
                    <span className="highlightContent">{agreementdate}</span>
                    <br /> The rent shall be paid on or before{" "}
                    <span className="highlightContent">{rentdate}</span> day of
                    each month without fail.
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Period of Rent</td>
                  <td>
                    <span className="highlightContent">{agreementperiod}</span>{" "}
                    (<span className="highlightContent">{agreementdate}</span>{" "}
                    to <span className="highlightContent">26/11/2023</span>)
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Security Deposit</td>
                  <td>
                    <span className="highlightContent">{securityamount}</span>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Electricity/ Water Charges</td>
                  <td>
                    As per Meters payable and billing cycle, to be paid by
                    Tenant before the due dates.
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Furnishings</td>
                  <td>
                    <span className="highlightContent">
                      {bhk}, {furnishing} <br />
                    </span>
                    <span className="highlightContent">
                      {addItemList.map((data, index) => {
                        if (data.quantity > 0) {
                          return (
                            <>
                              {data.name} - {data.quantity}
                              {", "}
                            </>
                          );
                        } else {
                          return;
                        }
                      })}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <b>
                IN WITNESS WHEREOF BOTH PARTIES AGREES AND SIGNS THIS RENTAL
                AGREEMENT ON THIS DAY AND YEAR
              </b>
            </p>
            <p>
              <b>WITNESSES:</b>
            </p>
            <p>
              1. (Name of the Witness)
              <br /> (Signature of the Witness)
              <br /> (Address of the Witness)
            </p>
            <p>
              2. (Name of the Witness)
              <br /> (Signature of the Witness)
              <br /> (Address of the Witness)
            </p>
            <div className="grid">
              <div className="col-6">
                <p>(Signature of the Owner)</p>
                <p>(Name of the Owner)</p>
                <p>
                  <b>OWNER</b>
                </p>
              </div>
              <div className="col-6">
                <p>(Signature of the Tenant)</p>
                <p>(Name of the Tenant)</p>
                <p>
                  <b>TENANT</b>
                </p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};
