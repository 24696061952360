import React from "react";
import { useSelector } from "react-redux";

export const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <h1>Dashboard</h1>
      <h2>
        Welcome back <b>{user && user.name}</b>
      </h2>
    </div>
  );
};
