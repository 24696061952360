import React from "react";
import "./Timeline.css";

export const Timeline = () => {
  return (
    <>
      <div className="section-pt position-relative">
        <div className="container">
          <section className="timeline-sec">
            <div className="wrapper">
              <div className="timeline-main">
                <div className="timeline-row">
                  <div className="timeline-box">
                    <h6>1</h6>
                    <h5>Collection of documents</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>2</h6>
                    <h5>Validation of documents</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>3</h6>
                    <h5>Preparation of draft documents</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="horizontal-line"></div>
                  <div className="verticle-line"></div>
                  <div className="corner top"></div>
                  <div className="corner bottom"></div>
                </div>
                <div className="timeline-row">
                  <div className="timeline-box">
                    <h6>4</h6>
                    <h5>Proof-reading</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>5</h6>
                    <h5>Final Documents accepted for execution</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>6</h6>
                    <h5>Horoscope based slot booking</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="horizontal-line"></div>
                  <div className="verticle-line"></div>
                  <div className="corner top"></div>
                  <div className="corner bottom"></div>
                </div>
                <div className="timeline-row">
                  <div className="timeline-box">
                    <h6>7</h6>
                    <h5>Registration of documents at Sub-Registrar Office</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>8</h6>
                    <h5>Handing over of executed documents</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                  <div className="timeline-box">
                    <h6>9</h6>
                    <h5>Blockchain storage of property details</h5>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla augue justo, rhoncus non ultricies non, sagittis ut
                    nisl.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
