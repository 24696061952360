import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const FormEditUser = () => {
  const role = [
    { name: "Admin", value: "admin" },
    { name: "User", value: "user" },
  ];
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [roles, setRoles] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const defaultValues = {
    name: name,
    email: email,
    password: password,
    confPassword: confPassword,
    role: roles,
  };

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(`https://mysro.in/patch/users/${id}`, {
        name: name,
        email: email,
        password: password,
        confPassword: confPassword,
        role: roles,
      });
      navigate("/users");
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };
  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get(`https://mysro.in/get/users/${id}`);
        setName(response.data.name);
        setEmail(response.data.email);
        setPassword(response.data.password);
        setConfPassword(response.data.confPassword);
        setRoles(response.data.role);
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data.msg);
        }
      }
    };
    getUserById();
  }, [id]);

  return (
    <div>
      <h1>Users</h1>
      <h2>Edit User</h2>

      <div className="card">
        <p className="text-center">{message}</p>
        <form onSubmit={onSubmit} className="p-fluid">
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="name"
                className={classNames({ "p-error": errors.name })}
              >
                Name*
              </label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label p-input-icon-right">
              <i className="pi pi-envelope" />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address. E.g. example@email.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="email"
                className={classNames({ "p-error": !!errors.email })}
              >
                Email*
              </label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="password"
                control={control}
                rules={{ required: "Password is required." }}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    toggleMask={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="password"
                className={classNames({ "p-error": errors.password })}
              >
                Password*
              </label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="confPassword"
                control={control}
                rules={{ required: "Confirm Password does not matched." }}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    toggleMask={true}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="confPassword"
                className={classNames({ "p-error": errors.password })}
              >
                Confirm Password*
              </label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    value={roles}
                    onChange={(e) => setRoles(e.target.value)}
                    options={role}
                    optionLabel="name"
                  />
                )}
              />
              <label htmlFor="role">Role</label>
            </span>
          </div>
          <Button type="submit" label="Update" className="mt-2" />
        </form>
      </div>
    </div>
  );
};
