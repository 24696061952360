import React from "react";
import style from "./CounterSection.module.css";
import Counter from "./counter.json";
import { Count } from "./Count";

export const CounterSection = () => {
  return (
    <div className="section-pt section-pb position-relative">
      <div className="container">
        <h3 className={style.title}>Highlights of mySRO - in numbers</h3>
        <div className="grid">
          {Counter.counter.map((a, index) => {
            return (
              <div className="md:col-3" key={index}>
                <p className={style.icon}>
                  <img src={a.icon} alt="partner" />
                </p>
                <Count count={a.count}></Count>
                <h6 className={`mb-6" ${style.subTitle}`}>{a.heading}</h6>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
