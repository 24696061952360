import React from "react";
import "./About.css";
import { Card } from "primereact/card";
import AboutUs from "../JSON/about";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Timeline } from "../CommonComponent/Timeline/Timeline";

export const About = () => {
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt position-relative">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different </span>
                  because...
                </h3>
                <p className="mb-6">
                  We care for your dream property bought out of your hard-earned
                  life savings!
                </p>
              </div>
            </div>
          </div>
          <div className="grid">
            {AboutUs.aboutUs.map((item, i) => (
              <div className="md:col-4" key={i}>
                <Card className="featureCard h-full">
                  <span className="card-shape card-shape-light"></span>
                  <span className="card-shape card-shape-dark"></span>
                  <img className="logo" src={item.icon} alt=" feature logo" />
                  <h4 className="title my-3">
                    <a href="/about">{item.heading}</a>
                  </h4>
                  <ul className="list-disc pl-5">
                    {item.description.map((list, index) => (
                      <li key={index} className="pb-4">
                        {list.list}
                      </li>
                    ))}
                  </ul>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Timeline />
      <Footer />
    </>
  );
};
