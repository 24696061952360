import { createSlice } from "@reduxjs/toolkit";

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    value: "",
  },
  reducers: {
    setPropertyType: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setPropertyType } = appointmentSlice.actions;
export const selectedValue = (state) => state.appointment.value;
export default appointmentSlice.reducer;
