import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { AboutSection } from "./AboutSection/AboutSection";
import { ServiceSection } from "./ServiceSection/ServiceSection";
import homeServices from "../JSON/homeServices";
import { TestimonialSection } from "./TestimonialSection/TestimonialSection";
import { CounterSection } from "./CounterSection/CounterSection";
import styles from "./Home.module.css";
import { ContactSection } from "./ContactSection/ContactSection";

export const Home = () => {
  return (
    <>
      <Header />
      <section>
        <div className={styles.bannerBg}>
          <div className={styles.item}>
            <div>
              <div className={styles.heading}>
                Your property's best friend - MySRO.in <br />
                India's 1st & only 'One-stop' platform for property registration
              </div>
              <a
                className={styles.view_more}
                title="call us"
                href="tel:+917845785781"
              >
                Call Us
              </a>
              <a
                className={styles.contact_us}
                title="Book Free Consultation"
                href="/contact"
              >
                Book Free Consultation
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        <div className="container">
          <h3 className={`${styles.subTitle} ${styles.ptb1}`}>
            Experience the convenience of property registration from start to
            finish with mySRO, all from the comfort of your home
          </h3>
          <p className="text-center">
            <a href="/about" className={styles.btn_dark}>
              Know More
            </a>
          </p>
          <div className="grid">
            {homeServices.homeServices.map((item, i) => (
              <div className="md:col-4" key={i}>
                <div className="service-item">
                  <figure className="service-thumb">
                    <a href={item.link}>
                      <img src={`${item.image}`} alt="test" />
                    </a>
                    <figcaption className="service-txt">
                      <h5>{item.heading}</h5>
                    </figcaption>
                  </figure>
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h5>
                        <a href={item.link} className="stretched-link">
                          {item.heading}
                        </a>
                      </h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AboutSection show={false} />
      <TestimonialSection />
      <CounterSection />
      <ContactSection />
      <Footer />
    </>
  );
};
