import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

export const AddItemList = ({ basicDetail }) => {
  const {
    control,
    formState: { errors },
    watch,
    resetField,
  } = useFormContext();
  const {
    fields: itemField,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "addItemList",
  });

  const watchFieldArray = watch("addItemList");
  const controlledFields = itemField.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const getFormErrorMessage = (name, index) => {
    return (
      errors["addItemList"] && (
        <small className="p-error">
          {errors["addItemList"][index][name] &&
            errors["addItemList"][index][name].message}
        </small>
      )
    );
  };
  const [visible, setVisible] = useState(false);
  const addItemList = [
    {
      name: "Fans",
      quantity: undefined,
    },
    {
      name: "Chair",
      quantity: undefined,
    },
    {
      name: "Sofa",
      quantity: undefined,
    },
    {
      name: "Table",
      quantity: undefined,
    },
    {
      name: "Bed",
      quantity: undefined,
    },
    {
      name: "Wooden",
      quantity: undefined,
    },
    {
      name: "cupboards",
      quantity: undefined,
    },
    {
      name: "Dining Table",
      quantity: undefined,
    },
    {
      name: "Air Conditioners",
      quantity: undefined,
    },
    {
      name: "Air Coolers",
      quantity: undefined,
    },
    {
      name: "Geysers",
      quantity: undefined,
    },
    {
      name: "TV",
      quantity: undefined,
    },
    {
      name: "Fridge",
      quantity: undefined,
    },
    {
      name: "Oven",
      quantity: undefined,
    },
    {
      name: "Washing Machine",
      quantity: undefined,
    },
    {
      name: "Study Table",
      quantity: undefined,
    },
    {
      name: "Gas stove",
      quantity: undefined,
    },
    {
      name: "Modular Kitchen",
      quantity: undefined,
    },
    {
      name: "Chimney",
      quantity: undefined,
    },
  ];
  //   useEffect(() => {
  //     if (controlledFields.length > 19) {
  //       controlledFields.map((_, i) => {
  //         if (controlledFields.length > 19 && _.quantity < 1) {
  //           remove(i);
  //         }
  //       });
  //     }
  //   }, [controlledFields]);

  return (
    <>
      <div className="p-fluid pt-5">
        <label htmlFor="name">Add Fittings and Furnishings (Optional)</label>
      </div>
      {controlledFields.map((data, index) => {
        return (
          <div className="p-fluid pt-5" key={index}>
            <div className="field m-auto width-50">
              <span className="">
                <Controller
                  name={`addItemList.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className="flex align-item-center">
                        <label
                          htmlFor="addItemList"
                          className="font-bold block mb-2"
                        >
                          {data.name}
                        </label>
                        <InputNumber
                          className={`itemIncDec ${
                            field.value === (0 | undefined)
                              ? "hideDecrIput"
                              : ""
                          }`}
                          id={field.name}
                          {...field}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            // if (index > 18 && data.quantity < 1) {
                            //   remove(index);
                            // }
                          }}
                          inputRef={field.ref}
                          showButtons
                          buttonLayout="horizontal"
                          decrementButtonClassName="p-button-secondary decrementButtonClassName"
                          incrementButtonClassName="p-button-secondary"
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                        />
                      </div>
                    </>
                  )}
                />
              </span>
            </div>

            {/* {index >= 1 && (
              <Button
                onClick={() => remove(index)}
                label="Remove"
                type="button"
              />
            )} */}
          </div>
        );
      })}
      <Button
        onClick={() =>
          //   append({
          //     name: "",
          //   })
          setVisible(true)
        }
        label="Add New"
        type="button"
      />
      <Dialog
        header="Header"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <Controller
          name={`additional`}
          control={control}
          render={({ field }) => (
            <>
              <InputText
                name="additional"
                onChange={(e) => field.onChange(e.target.value)}
              />
              <Button
                onClick={() => {
                  append({
                    name: field.value,
                    quantity: 1,
                  });
                  setVisible(false);
                  resetField("additional", "");
                }}
                label="Add New"
                type="button"
              />
            </>
          )}
        />
      </Dialog>
    </>
  );
};
