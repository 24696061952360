import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import "./Contact.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import axios from "axios";

export const Contact = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    addMessage: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  // const onSubmit = (data) => {
  //   setFormData(data);
  //   setShowMessage(true);

  //   reset();
  // };

  const onSubmit = async (e) => {
    try {
      await axios.post("https://mysro.in/post/contact", {
        name: e.name,
        email: e.email,
        phone: e.phone,
        addMessage: e.addMessage,
      });
      setFormData(e);
      setShowMessage(true);
      reset();
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative">
        <div className="container">
          <div className="grid">
            <div className="col-6">
              <h3 className="title">Get in touch</h3>
              <p>
                Top rated construction packages we pleasure rationally encounter
                <br />
                consequences interesting who loves or pursue or desires
              </p>
              <div className="contactForm">
                <Dialog
                  visible={showMessage}
                  onHide={() => setShowMessage(false)}
                  position="top"
                  footer={dialogFooter}
                  showHeader={false}
                  breakpoints={{ "960px": "80vw" }}
                  style={{ width: "30vw" }}
                >
                  <div className="flex justify-content-center flex-column pt-6 px-3">
                    <i
                      className="pi pi-check-circle"
                      style={{ fontSize: "5rem", color: "var(--green-500)" }}
                    ></i>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
                      Your account is registered under name{" "}
                      <b>{formData.name}</b> ; it'll be valid next 30 days
                      without activation. Please check <b>{formData.email}</b>{" "}
                      for activation instructions.
                    </p>
                  </div>
                </Dialog>

                <div className="">
                  <div className="card">
                    <p className="text-center">{message}</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                      <div className="grid">
                        <div className="col-6 field">
                          <span className="p-float-label">
                            <Controller
                              name="name"
                              control={control}
                              rules={{ required: "Name is required." }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label
                              htmlFor="name"
                              className={classNames({ "p-error": errors.name })}
                            >
                              Name*
                            </label>
                          </span>
                          {getFormErrorMessage("name")}
                        </div>
                        <div className="col-6 field">
                          <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <Controller
                              name="email"
                              control={control}
                              rules={{
                                required: "Email is required.",
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message:
                                    "Invalid email address. E.g. example@email.com",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  id={field.name}
                                  {...field}
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label
                              htmlFor="email"
                              className={classNames({
                                "p-error": !!errors.email,
                              })}
                            >
                              Email*
                            </label>
                          </span>
                          {getFormErrorMessage("email")}
                        </div>
                        <div className="col-12 field">
                          <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-phone" />
                            <Controller
                              name="phone"
                              control={control}
                              rules={{
                                required: "Phone number is required.",
                                pattern: {
                                  value: /^[0-9]{10}$/i,
                                  message:
                                    "Invalid phone number address. E.g. 9000000000",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  id={field.name}
                                  {...field}
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label
                              htmlFor="phone"
                              className={classNames({
                                "p-error": !!errors.phone,
                              })}
                            >
                              Phone*
                            </label>
                          </span>
                          {getFormErrorMessage("phone")}
                        </div>
                        <div className="col-12 field">
                          <span className="p-float-label p-input-icon-right">
                            <Controller
                              name="addMessage"
                              control={control}
                              rules={{}}
                              render={({ field, fieldState }) => (
                                <InputTextarea
                                  id={field.name}
                                  {...field}
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                />
                              )}
                            />
                            <label
                              htmlFor="addMessage"
                              className={classNames({
                                "p-error": !!errors.msg,
                              })}
                            >
                              Message*
                            </label>
                          </span>
                          {getFormErrorMessage("addMessage")}
                        </div>
                      </div>
                      <Button type="submit" label="Submit" className="mt-2" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5 col-offset-1">
              <div className="contact-address text-center">
                <div className="address-list mb-6 pb-2">
                  <h4 className="title">Office Addres</h4>
                  <p>Nungambakkam, Chennai</p>
                </div>
                <div className="address-list mb-6 pb-2">
                  <h4 className="title">Phone Number</h4>
                  <ul>
                    <li>
                      <a className="phone-number" href="tel:+917845785781">
                        +91 784 578 5781
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="address-list">
                  <h4 className="title">Web Address</h4>
                  <ul>
                    <li>
                      <a className="mailto" href="mailto:info@mysro.in">
                        info@mysro.in
                      </a>
                    </li>
                    <li>
                      <a
                        className="mailto"
                        href="https://mysro.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.mysro.in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
