import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Appointment } from "./Appointment/Appointment";
import { HashLink } from "react-router-hash-link";

export const Header = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [displayBasic, setDisplayBasic] = useState(false);
  const onClickPopup = () => setDisplayBasic(!displayBasic);
  const [mySROLogo, setMySROLogo] = useState("/images/whiteLogo.svg");

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setDisplayBasic(true);
    }, 2000);
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector(".navbar");
    const scrollTop = window.scrollY;
    if (scrollTop >= 150) {
      setMySROLogo("/images/logo.svg");
      header.classList.add("is-sticky");
    } else {
      setMySROLogo("/images/whiteLogo.svg");
      header.classList.remove("is-sticky");
    }
  };
  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()}>
        {/* <div className="top-bar-area">
          <div className="container">
            <div className="grid">
              <div className="col-9">
                <div className="info box">
                  <ul>
                    <li>
                      <div className="icon">
                        <i className="pi pi-map-marker"></i>
                      </div>
                      <div className="info">
                        <p>California, TX 70240</p>
                      </div>
                    </li> 
                    <li>
                      <div className="icon">
                        <i className="pi pi-envelope"></i>
                      </div>
                      <div className="info">
                        <p>Info@gmail.com</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="pi pi-mobile"></i>
                      </div>
                      <div className="info">
                        <p>+123 456 7890</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-3 topbar-social">
                <ul className="text-right">
                  <li>
                    <a href="/about">
                      <i className="pi pi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <i className="pi pi-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <i className="pi pi-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <i className="pi pi-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <i className="pi pi-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div className="height80">
          <nav className="navbar" onClick={(e) => e.stopPropagation()}>
            <div className="nav-container">
              <NavLink exact="true" to="/" className="nav-logo">
                <img className="mySROLogo" src={mySROLogo} alt="  logo" />
              </NavLink>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <NavLink
                    exact="true"
                    to="/"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    exact="true"
                    to="/services"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Our Services
                  </NavLink>
                  <ul className="dropdownList">
                    <li className="nav-item">
                      <HashLink smooth to="/services/property-registration">
                        Property Registration
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/services/mysro-property-report">
                        mySRO Property report
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/services">
                        Rental Agreement
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/services">
                        Legal consultation
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/services">
                        mySRO valuation report
                      </HashLink>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    exact="true"
                    to="/about"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Discover mySRO
                  </NavLink>
                  <ul className="dropdownList">
                    <li className="nav-item">
                      <HashLink smooth to="/about">
                        About Us
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/">
                        Our Partners
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/">
                        Our Future Plans
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/">
                        Gallery
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/careers">
                        Careers
                      </HashLink>
                    </li>
                    <li className="nav-item currency">
                      <HashLink smooth to="/contact">
                        Contact Us
                      </HashLink>
                    </li>
                  </ul>
                </li>

                {/* <li className="nav-item">
                  <NavLink
                    exact="true"
                    to="/about"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Discover mySRO
                  </NavLink>
                </li> 
                <li className="nav-item">
                  <NavLink
                    exact="true"
                    to="/innovation-and-technology"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Innovation & Technology
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact="true"
                    to="/careers"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Careers
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    exact="true"
                    to="/contact"
                    activeclassname="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Contact Us
                  </NavLink>
                </li> */}
                <li className="appButton">
                  <Button
                    className="appointment"
                    aria-label="appointment"
                    onClick={onClickPopup}
                  >
                    <span className="px-3">Get Free Consultation</span>
                  </Button>
                  <Dialog
                    headerStyle={{ textAlign: "center" }}
                    onHide={onClickPopup}
                    header="Book your free consultation"
                    visible={displayBasic}
                    style={{ width: "65vw" }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    headerClassName="consultaionHeading"
                    blockScroll={true}
                    draggable={false}
                  >
                    <Appointment />
                  </Dialog>
                </li>
              </ul>
              <div className="nav-icon" onClick={handleClick}>
                <i className={click ? "pi pi-times" : "pi pi-bars"}></i>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
