import React, { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";

export const TenantDetails = ({ basicDetail }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    resetField,
  } = useFormContext();
  const {
    fields: docField,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "tenantDetails",
  });

  const watchFieldArray = watch("tenantDetails");
  const controlledFields = docField.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const getFormErrorMessage = (name, index) => {
    return (
      errors["tenantDetails"] && (
        <small className="p-error">
          {errors["tenantDetails"][index][name] &&
            errors["tenantDetails"][index][name].message}
        </small>
      )
    );
  };
  //   useEffect(() => {
  //     {
  //       basicDetail.iam === "Tenant" &&
  //         setValue("tenantDetails.0.name", basicDetail.name);
  //       setValue("tenantDetails.0.state", basicDetail.state);
  //       setValue("tenantDetails.0.city", basicDetail.city);
  //       setValue("tenantDetails.0.email", basicDetail.email);
  //       setValue("tenantDetails.0.mobile", basicDetail.mobile);
  //     }
  //   }, [basicDetail.iam]);
  return (
    <>
      {controlledFields.map((data, index) => {
        return (
          <div className="p-fluid pt-5" key={index}>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.name`}
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="name">Full Name*</label>
              </span>
              {getFormErrorMessage("name", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.parentname`}
                  control={control}
                  rules={{ required: "Father/ Mother's Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="parentname">Father/ Mother's Name*</label>
              </span>
              {getFormErrorMessage("parentname", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.address`}
                  control={control}
                  rules={{
                    required: "Address is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="address">Permanent Address*</label>
              </span>
              {getFormErrorMessage("address", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.city`}
                  control={control}
                  rules={{ required: "City is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="city">City*</label>
              </span>
              {getFormErrorMessage("city", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.state`}
                  control={control}
                  rules={{ required: "State is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="state">State*</label>
              </span>
              {getFormErrorMessage("state", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.pincode`}
                  control={control}
                  rules={{ required: "Pin code is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="pincode">Pin code*</label>
              </span>
              {getFormErrorMessage("pincode", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.mobile`}
                  control={control}
                  rules={{ required: "Mobile number is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="mobile">Mobile Number*</label>
              </span>
              {getFormErrorMessage("mobile", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.email`}
                  control={control}
                  rules={{ required: "Email is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="email">Email ID*</label>
              </span>
              {getFormErrorMessage("email", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.panid`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="panid">PAN ID (Optional)</label>
              </span>
              {getFormErrorMessage("panid", index)}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name={`tenantDetails.${index}.aadhaar`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label htmlFor="aadhaar">Aadhaar ID (Optional)</label>
              </span>
              {getFormErrorMessage("aadhaar", index)}
            </div>
            {index >= 1 && (
              <Button
                onClick={() => remove(index)}
                label="Remove"
                type="button"
              />
            )}
          </div>
        );
      })}
      <Button
        onClick={() =>
          append({
            docNumber: "",
            docYear: "",
            subRegOffice: "",
            regAddress: "",
          })
        }
        label="Add Tenant"
        type="button"
      />
    </>
  );
};
