import React, { useEffect, useState } from "react";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import { useDispatch } from "react-redux";
import MPRFAQs from "./MPRFAQs.json";
import { setPropertyType } from "../../Header/Appointment/appointmentSlice";
import { Accordion, AccordionTab } from "primereact/accordion";
import style from "./MysroPropertyReport.module.css";
import "./MysroPropertyReport.css";
import "../Service.css";
import { MysroBasicFrom } from "./MysroBasicForm";

export const MysroPropertyReport = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    dispatch(setPropertyType("mySRO Property Report"));
  }, []);
  return (
    <>
      <Header />
      <div className=" position-relative">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <h1 className="service_ParentHeading">
                    mySRO Property report
                  </h1>
                  <p>
                    A comprehensive report prepared by legal experts providing
                    detailed information and opinion about your property's legal
                    status, ownership history, tax records and any encumbrances
                    or liens, in addition to our signature mySRO legal score.{" "}
                  </p>
                  <a className={style.linkHighLight}>View sample report</a>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative">
                <div className="howItWorksBox">
                  <div className="howItWorks">
                    <h3 className="fontColor283552 text-center">
                      Book your Appointment
                    </h3>
                    <MysroBasicFrom />
                  </div>
                  <div className="RequestReport">
                    <h5
                      className={`fontColor283552 text-left m-0 appo_Heading`}
                    >
                      How does it work:
                    </h5>
                    <div className="appoList">
                      <p>
                        <span>
                          <b>Fill property details online</b>
                        </span>
                        <br />
                        Share the basic information about your property
                      </p>
                      <p>
                        <span>
                          <b>Make your online payment</b>
                        </span>
                        <br />
                        Kindly do the payment in accordance with your service
                        needs.
                      </p>
                      <p>
                        <span>
                          <b>Delivered to your home</b>
                        </span>
                        <br />
                        Enjoy peace at home as we bring the report to your
                        doorstep.
                      </p>
                    </div>
                    {/* <ul className={styles.list}>
                      <li>
                        <span>
                          <b>Fill property details online</b>
                        </span>
                        <br />
                        Share the basic information about your property
                      </li>
                      <li>100 percent legal, Safe and transparent</li>
                      <li>End-to-End process at your doorstep</li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <div className="">
                    <h1 className="service_ParentHeading">
                      Why mySRO property report?
                    </h1>
                  </div>
                  <div className="listHeading">
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <h4>Legal Clarity</h4>

                        <p>
                          Clear and detailed overview of the property's legal
                          status, ownership history, and any issues.
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <h4>Risk Mitigation</h4>

                        <p>
                          Identifies potential legal problems, allowing buyers
                          and sellers to address them early, reducing future
                          complications.
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <h4>Informed Decision-Making</h4>

                        <p>
                          Helps Buyers and sellers make well-informed decisions,
                          including negotiations on price and terms.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bge4f7f9 position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <div className="">
                    <h1 className="service_ParentHeading">
                      mySRO Property score:
                    </h1>
                    <p>
                      he mySRO Property Score is a numerical representation of a
                      property's legal status, with a higher score indicating
                      lower legal risk and a more secure investment. This score
                      aids property buyers, sellers, and investors in swiftly
                      assessing the property's legal status, enabling informed
                      decisions in real estate transactions.
                    </p>
                    <p className="pb-5">
                      Scores are calculated by reviewing various parameters,
                      including a thorough examination of property documents
                      such as parent documents, patta and chitta, Encumbrance
                      Certificate (EC), tax records, and sale deeds. Multiple
                      factors are taken into account, such as:
                    </p>
                  </div>
                  <div className="listHeading">
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          <b className="fontColor283552">
                            Ownership Verification:
                          </b>{" "}
                          Assessing the clarity and authenticity of property
                          ownership documents
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          <b className="fontColor283552">Historical Data:</b>{" "}
                          Identifying any encumbrances or liens on the property
                          that could impede its transfer.
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          <b className="fontColor283552">Legal Opinion:</b> If a
                          legal opinion is provided within the report, it may
                          impact the score based on the recommendations and
                          evaluations made.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>
      <div className=" position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <div className="">
                    <h1 className="service_ParentHeading">
                      What's the ideal timing for conducting a mySRO property
                      report?
                    </h1>
                  </div>
                  <div className="listHeading">
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          Conclude a property transaction
                          <br />
                          a) Locate the property of your choice and engage in
                          price negotiations
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          Documents verification <br />
                          a) Panel experts verify the documents before you close
                          the deal
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          Property registration <br />
                          a) End to End property registration at your doorstep
                        </p>
                      </div>
                    </div>
                    <div className="deedlist">
                      <i
                        className={`pi pi-angle-double-right deedlistIcon`}
                      ></i>
                      <div>
                        <p>
                          Dream Property owner <br />
                          a) Congratulations! You are now the owner of your
                          dream property
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bge4f7f9 position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <h1 className="service_ParentHeading">
                    Frequently asked question
                  </h1>
                  <div className="">
                    <Accordion
                      activeIndex={activeIndex}
                      onTabChange={(e) => setActiveIndex(e.index)}
                      className="faqTabs"
                    >
                      {MPRFAQs.MPRFAQs.map((list, index) => {
                        return (
                          <AccordionTab
                            header={list.question}
                            headerClassName="accordionHeader"
                            key={"PRFAQs" + index}
                          >
                            <p>{list.ans}</p>
                          </AccordionTab>
                        );
                      })}
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="position-relative">
        <div className="container">
          <div className="bg26567e boxSpacing">
            <div className="grid mt-0">
              <div className="col-5 p-0">
                <img
                  src="https://cdn.spark.app/media/housingcom/icon/property_management_illustration_36_36.svg"
                  alt="mySRO"
                />
                <div className="boxInfo">
                  <h1>Your Home is our Priority.</h1>
                  <ul className="list-disc pl-5">
                    <li>ZERO Sign-up Fees</li>
                    <li>ZERO Tenant Replacement Fees</li>
                    <li> Flexible Payment Options</li>
                    <li> Online Dashboards</li>
                    <li>Dedicated Property Manager</li>
                  </ul>
                </div>
              </div>
              <div className="col-2"></div>
              <div className="col-5">
                <div className="howItWorksBox">
                  <div className="howItWorks">
                    <h3>How does it work?</h3>
                    <ul className="">
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Submit your details</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Submit your details</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Submit your details</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Submit your details</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="RequestReport">
                    <HashLink
                      smooth
                      to="/services/valuation-report#requestForReport"
                      className="p-button p-component appointment"
                    >
                      Request for a Report
                    </HashLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative" id="requestForReport">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="mb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-6 col-offset-3">
              <MysroFormReport />
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="requestForReport">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  When is the right time for Property Legal Title Check?
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flexbox">
        <div className="flex flexbottom">
          <h2 className="stepNumber">01</h2>
          <div className="textbox">
            <h4 className="m-0">Finalize a property</h4>
            <p>
              Find the property you want to buy & negotiate on the final price
            </p>
          </div>
          <div className="imgbox">
            <img
              src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex flextop">
          <div className="imgbox">
            <img src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126876_960_720.png" />
          </div>
          <h2 className="stepNumber">02</h2>
          <div className="textbox">
            <h4 className="m-0">Documents verification</h4>
            <p>Let experts verify the documents before you close the deal</p>
          </div>
        </div>
        <div className="flex flexbottom">
          <h2 className="stepNumber">03</h2>
          <div className="textbox">
            <h4 className="m-0">Apply for a Home Loan</h4>
            <p>After the verification of documents, apply for a home loan</p>
          </div>
          <div className="imgbox">
            <img
              src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126878_960_720.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex flextop">
          <div className="imgbox">
            <img
              src="https://cdn.pixabay.com/photo/2017/01/10/23/01/vector-1970471_960_720.png"
              alt=""
            />
          </div>
          <h2 className="stepNumber">04</h2>
          <div className="textbox">
            <h4 className="m-0">Move in</h4>
            <p>Take complete possession of the property</p>
          </div>
        </div>
        <div className="flex flexbottom">
          <h2 className="stepNumber">05</h2>
          <div className="textbox">
            <h4 className="m-0">Finalize a property</h4>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure,
              illo.
            </p>
          </div>
          <div className="imgbox">
            <img
              src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126884_960_720.png"
              alt=""
            />
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};
