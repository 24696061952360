import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const FormAddUserDoc = () => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const navigate = useNavigate();
  const defaultValues = {
    name: name,
    price: price,
  };

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://mysro.in/post/usersdoc", {
        name: name,
        price: price,
      });
      navigate("/usersdoc");
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };
  return (
    <div>
      <h1>User Doc</h1>
      <h2>Add User Document</h2>

      <div className="card">
        <p className="text-center">{message}</p>
        <form onSubmit={onSubmit} className="p-fluid">
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="name"
                className={classNames({ "p-error": errors.name })}
              >
                Name*
              </label>
            </span>
          </div>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="price"
                control={control}
                rules={{ required: "Price is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="price"
                className={classNames({ "p-error": errors.name })}
              >
                Price*
              </label>
            </span>
          </div>

          <Button type="submit" label="Save" className="mt-2" />
        </form>
      </div>
    </div>
  );
};
