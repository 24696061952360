import React, { useState, useEffect } from "react";
import style from "./CounterSection.module.css";

export const Count = (props) => {
  const [counts, setCount] = useState("0");
  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(props.count.substring(0, 3));
    // if zero, return
    if (start === end) return;
    // find duration per increment
    // let totalMilSecDur = parseInt(duration);
    let incrementTime = (2 / end) * 1000;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + props.count.substring(3));
      if (start === end) clearInterval(timer);
    }, incrementTime);
  }, [props.count]);
  return (
    <div className="Count">
      <h3 className={style.count}>
        {counts}
        <span className="text-primary"></span>
      </h3>
    </div>
  );
};
