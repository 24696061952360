import React from "react";
import "./Careers.css";
import { Button } from "primereact/button";
import CareersList from "../JSON/careers";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const Careers = () => {
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>Careers</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative careersSection">
        <div className="container">
          <div className="grid">
            {CareersList.careers.map((item, i) => (
              <div className="col-6" key={i}>
                <h3 className="title">{item.heading}</h3>
                <p>{item.description}</p>
                <Button
                  className="applyNow"
                  aria-label="Apply Now"
                  onClick={() => {}}
                >
                  Apply Now
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
