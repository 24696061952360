import React, { useState } from "react";
import { Steps } from "primereact/steps";
import { FormPropertyDetails } from "./FormPropertyDetails";
import { FormUserDetail } from "./FormUserDetail";
import "./MysroPropertyReport.css";
import { PreviewDetails } from "./PreviewDetails";
import { Button } from "primereact/button";
import { FormProvider, useForm } from "react-hook-form";
import { Message } from "primereact/message";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const MysroFormReport = (props) => {
  const items = [
    {
      label: "Personal Details",
    },
    {
      label: "Document Details",
    },
    {
      label: "Preview Data",
    },
  ];
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState(undefined);
  const [file, setFile] = useState([]);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  const handlePropertyTypeChange = (e) => {
    setValue("selectedPropertyType", e.target.value);
    setSelectedPropertyType(e.target.value);
  };
  const fileNames = Array.from(file).map((file) => file);
  const handleFileChange = (e) => {
    setValue("fileUpload", e.target.files);
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) => file.name);
      setFileList(fileArray);
      setFile(e.target.files);
    }
  };

  const defaultValues = {
    name: props.inputName || "",
    email: props.inputEmail || "",
    mobile: props.inputPhone || "",
    address: "",
    docDetails: [
      { docNumber: "", docYear: "", subRegOffice: "", regAddress: "" },
    ],
    selectedPropertyType: [],
    fileUpload: "",
  };
  const deleteFile = (e) => {
    const s = fileNames.filter((item, index) => index !== e);
    setFile(s);
  };

  const cases = () => {
    switch (step) {
      case 0:
        return <FormUserDetail />;
      case 1:
        return (
          <FormPropertyDetails
            handleFileChange={handleFileChange}
            handlePropertyTypeChange={handlePropertyTypeChange}
            selectedFile={file}
            fileNames={fileNames}
            deleteFile={deleteFile}
          />
        );
      case 2:
        return <PreviewDetails values={watchAllFields} fileView={fileList} />;

      default:
        return "Unknown step";
    }
  };

  const onSubmit = async (e) => {
    try {
      await axios.post("https://mysro.in/post/valuser", {
        name: e.name,
        email: e.email,
        mobile: e.mobile,
        address: e.address,
      });
      e.docDetails.map(async (list, index) => {
        await axios.post("https://mysro.in/post/reportdoc", {
          docNumber: list.docNumber,
          docYear: list.docYear,
          subRegOffice: list.subRegOffice,
          regAddress: list.regAddress,
        });
      });
      const fileData = new FormData();
      for (let i = 0; i < file.length; i++) {
        fileData.append("fileUpload", file[i]);
      }
      for (let i = 0; i < selectedPropertyType.length; i++) {
        fileData.append("selectedPropertyType", selectedPropertyType[i].name);
      }
      await axios.post("https://mysro.in/post/upload", fileData);

      setStatus({ type: "success" });
      setMessage("");
      setTimeout(() => {
        navigate("/services/mysro-property-report");
      }, 1000);
    } catch (error) {
      setStatus({ type: "error", error });
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, trigger, watch, setValue } = methods;
  const watchAllFields = watch();
  const nextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setStep((step) => step + 1);
  };
  const prevStep = () => {
    setStep((step) => step - 1);
  };

  return (
    <>
      {status?.type === "success" && (
        <Message
          severity="success"
          text="Succesfully created"
          className="mb-3"
        />
      )}
      {message && <Message severity="error" text={message} className="mb-3" />}
      <Steps model={items} activeIndex={step} className={`active${step + 1}`} />
      <FormProvider {...methods}>
        <form encType="multipart/form-data">
          {cases()}
          <div className="flex">
            {step !== 0 && (
              <Button
                label="Previous"
                className="mt-2 mr-2 darkBtn"
                type="button"
                onClick={prevStep}
              />
            )}
            {step === items.length - 1 ? (
              <Button
                label="Submit"
                type="submit"
                className="mt-2 darkBtn"
                onClick={handleSubmit(onSubmit)}
              />
            ) : (
              <Button
                label="Next"
                className="mt-2 darkBtn"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  nextStep();
                }}
              />
            )}
          </div>
        </form>
      </FormProvider>
    </>
  );
};
