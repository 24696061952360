import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer className="footer-area position-relative">
      {/* <div className="footer-top-wrap">
        <div className="footer-top-content">
          <div className="container">
            <div className="grid align-items-center">
              <div className="md:col-8">
                <h2>Get Started Today</h2>
                <p>
                  Businex always try to provide the best Business Solutions for
                  Clinets to grow up their Business sharply and smoothly.
                </p>
              </div>
              <div className="md:col-4 text-right">
                <Link to="/contact" className="btn-outline">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer-widget-area">
        <div className="container">
          <div className="grid mtn-40">
            <div className="md:col-4">
              <div className="widget-item">
                <div className="about-widget">
                  <Link to="/">
                    <img
                      className="mySROLogoFooter"
                      src="/images/whiteLogo.svg"
                      alt="logo"
                    />
                  </Link>
                  <p>
                    We care for your dream property bought out of your
                    hard-earned life savings!
                  </p>

                  <ul className="footer-social">
                    <li>
                      <a href="/about">
                        <i className="pi pi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/about">
                        <i className="pi pi-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/about">
                        <i className="pi pi-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/about">
                        <i className="pi pi-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/about">
                        <i className="pi pi-whatsapp"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:col-2 ml-auto">
              <div className="widget-item">
                <h4 className="widget-title">Information</h4>
                <div className="widget-body">
                  <ul className="widget-list">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Our Services</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:col-2 ml-auto">
              <div className="widget-item">
                <h4 className="widget-title">Quick Links</h4>
                <div className="widget-body">
                  <ul className="widget-list">
                    <li>
                      <a href="/">Facebook</a>
                    </li>
                    <li>
                      <a href="/">Twitter</a>
                    </li>
                    <li>
                      <a href="/">Dribbble</a>
                    </li>
                    <li>
                      <a href="/">Instagram</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="md:col-3">
              <div className="widget-item">
                <h4 className="widget-title">Contact</h4>
                <div className="widget-body">
                  <div className="footerInfo">
                    Nungambakkam, Chennai
                    <br />
                    <a className="" href="mailto:info@mysro.in">
                      info@mysro.in
                    </a>
                    <br />
                    <a className="" href="tel:+917845785781">
                      +91 784 578 5781
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copy-right-section">
        <div className="container">
          <div className="copyright-info text-center">
            <p>
              ©<script>document.write(new Date().getFullYear())</script>
              2023 mySRO. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
