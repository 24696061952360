import React, { useState } from "react";
import styles from "./Selector.module.css";

export const Selector = (props) => {
  // const [value, setValue] = useState("");
  // console.log(value);
  return (
    <>
      {props.items.map((item, index) => {
        return (
          <input
            name={props.name}
            type="radio"
            value={item}
            data-name={item}
            className={styles.checkbox}
            key={index}
            checked={props.value === item}
            onChange={props.onChange}
          />
        );
      })}
    </>
  );
};
