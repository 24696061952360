import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import axios from "axios";
import "../../Header/Appointment/Appointment.css";
import style from "../../Header/Appointment/Form.module.css";
import { useNavigate } from "react-router-dom";

export const MysroBasicFrom = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = (e) => {
    // e.preventDefault();
    navigate("/services/mysro-property-report/property-report-details", {
      state: { name: e.name, phone: e.phone, email: e.email },
    });
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );

  return (
    <>
      <Dialog
        visible={showMessage}
        onHide={() => setShowMessage(false)}
        position="top"
        footer={dialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <h5>Enquiry sent Successful!</h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
            Your enquiry is sent under name <b>{formData.name}</b>
          </p>
        </div>
      </Dialog>
      <p className="text-center">{message}</p>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="grid">
          <div className={`col-12 field ${style.topField}`}>
            <span className={`p-float-label ${style.floatLabel}`}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="name"
                className={classNames({ "p-error": errors.name })}
              >
                Name*
              </label>
            </span>
            {getFormErrorMessage("name")}
          </div>
          <div className={`col-12 field ${style.topField}`}>
            <span className={`p-float-label ${style.floatLabel}`}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required.",
                  pattern: {
                    value: /^[0-9]{10}$/i,
                    message: "Invalid phone number address. E.g. 9000000000",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="phone"
                className={classNames({
                  "p-error": !!errors.phone,
                })}
              >
                Phone*
              </label>
            </span>
            {getFormErrorMessage("phone")}
          </div>
          <div className={`col-12 field ${style.topField}`}>
            <span className={`p-float-label ${style.floatLabel}`}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address. E.g. example@email.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="email"
                className={classNames({
                  "p-error": !!errors.email,
                })}
              >
                Email*
              </label>
            </span>
            {getFormErrorMessage("email")}
          </div>
        </div>
        <Button
          type="submit"
          label="Request for Property Report"
          className={`mt-2 ${style.submitButton}`}
        />
      </form>
    </>
  );
};
