import React from "react";
import "./Appointment.css";
import style from "./Form.module.css";
import { AppointmentForm } from "./Form";

export const Appointment = () => {
  return (
    <div className={style.AppointmentForm}>
      <div className="grid">
        <div className={`md:col-6 ${style.borderRight}`}>
          <div>
            <h3 className={`${style.color283552} ${style.heading}`}>
              We Care for your Dream Property
            </h3>
            <div className="formLeftSection_middle">
              <h5 className={`${style.color283552} ${style.subHeading}`}>
                What will happen next ?
              </h5>
              <div className={style.formLeftSection}>
                <img
                  src="/images/Icon/Icons-06.svg"
                  alt="mySRO Way"
                  className={style.LeftIcon}
                />
                <p>
                  Once you book your free consultation, a dedicated relationship
                  manager will be assigned to you.
                </p>
                <img
                  src="/images/Icon/Icons-06.svg"
                  alt="mySRO Way"
                  className={style.LeftIcon}
                />
                <p>
                  Your Relationship Manager (RM) will get in touch with you
                  through a phone call.
                </p>
                <img
                  src="/images/Icon/Icons-06.svg"
                  alt="mySRO Way"
                  className={style.LeftIcon}
                />
                <p>
                  The RM will understand your specific requirements and
                  concerns, in detail.
                </p>
                <img
                  src="/images/Icon/Icons-06.svg"
                  alt="mySRO Way"
                  className={style.LeftIcon}
                />
                <p>
                  Based on the interaction, your consultation with our expert
                  panel will be scheduled at the earliest.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-6">
          <div className="contactForm">
            <div className="">
              <div className={style.card}>
                <AppointmentForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
