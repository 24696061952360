import React from "react";
import styles from "./Sidebar.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout, reset } from "../features/authSlice";
import { Button } from "primereact/button";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const logout = () => {
    dispatch(Logout());
    dispatch(reset());
    navigate("/login");
  };
  return (
    <div>
      <aside className={`${styles.sideBar}`}>
        <p className={`${styles.menuLabel}`}>General</p>
        <ul className={`${styles.menuList}`}>
          <li className={`nav-item ${styles.navItem}`}>
            <NavLink to="/dashboard" activeclassname="active">
              <i className="pi pi-home"></i> Dashboard
            </NavLink>
          </li>
          <li className={`nav-item ${styles.navItem}`}>
            <NavLink to="/usersdoc" activeclassname="active">
              <i className="pi pi-file"></i> User Doc
            </NavLink>
          </li>
        </ul>
        {user && user.role === "admin" && (
          <>
            <p className={`${styles.menuLabel}`}>Admin</p>
            <ul className={`${styles.menuList}`}>
              <li className={`nav-item ${styles.navItem}`}>
                <NavLink to="/users" activeclassname="active">
                  <i className="pi pi-users"></i> Users
                </NavLink>
              </li>
            </ul>
          </>
        )}

        <p className={`${styles.menuLabel}`}>Setting</p>
        <ul className={`${styles.menuList}`}>
          <li className={`nav-item ${styles.navItem}`}>
            <Button label="Logout" onClick={logout} />
            {/* <NavLink to="/logout" activeclassname="active">
              <i className="pi pi-power-off"></i> Logout
            </NavLink> */}
          </li>
        </ul>
      </aside>
    </div>
  );
};
