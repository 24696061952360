import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import "./FormStyles.css";
import { Selector } from "../../CommonComponent/Selector/Selector";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";

export const ContractDetails = ({ basicDetail, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  useEffect(() => {}, [props.city, props.state]);
  return (
    <>
      <div className="p-fluid pt-5 formInputSpace">
        <label htmlFor="agreementdate">Agreement start date*</label>
        <div className="field">
          <span className="">
            <Controller
              name="agreementdate"
              control={control}
              rules={{ required: "Agreement Date is required." }}
              render={({ field, fieldState }) => (
                <Calendar
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  showIcon
                />
              )}
            />
          </span>
          {getFormErrorMessage("agreementdate")}
        </div>
        <label htmlFor="agreementperiod">Period of agreement*</label>
        <div className="field">
          <span className="">
            <Controller
              name="agreementperiod"
              control={control}
              rules={{ required: "Period of agreement is required." }}
              render={({ field, fieldState }) => (
                <Selector
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  value={field.value}
                  items={[
                    "1 Month",
                    "2 Months",
                    "3 Months",
                    "4 Months",
                    "5 Months",
                    "6 Months",
                    "7 Months",
                    "8 Months",
                    "8 Months",
                    "10 Months",
                    "11 Months",
                  ]}
                  onChange={(e) => {
                    return field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </span>
          {getFormErrorMessage("agreementperiod")}
        </div>

        <div className="field">
          <span className="p-float-label">
            <Controller
              name="monthlyrent"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
            <label htmlFor="monthlyrent">Monthly Rent</label>
          </span>
        </div>
        <div className="flex align-items-center">
          <span className="">
            <Controller
              name="addChargesIncluded"
              control={control}
              //   rules={{ required: "Notice period is required." }}
              render={({ field, fieldState }) => (
                <>
                  <Checkbox
                    id={field.name}
                    {...field}
                    inputId="addChargesIncluded"
                    name="addChargesIncluded"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    checked={field.value}
                  />
                  <label htmlFor="addChargesIncluded" className="ml-2">
                    Electricity/Water/Maintenance charges will be paid by tenant
                    at actuals
                  </label>
                </>
              )}
            />
          </span>
        </div>
        <label htmlFor="agreementdate">Rent payment date*</label>
        <div className="field">
          <span className="">
            <Controller
              name="rentdate"
              control={control}
              rules={{ required: "Rent payment Date  is required." }}
              render={({ field, fieldState }) => (
                <>
                  <Dropdown
                    {...field}
                    id={field.name}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    options={[
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10",
                      "11",
                      "12",
                      "13",
                      "14",
                      "15",
                      "16",
                      "17",
                      "18",
                      "19",
                      "20",
                      "21",
                      "22",
                      "23",
                      "24",
                      "25",
                      "26",
                      "27",
                      "28",
                      "29",
                      "30",
                      "31",
                    ]}
                  />
                </>
              )}
            />
          </span>
          {getFormErrorMessage("rentdate")}
        </div>

        <div className="field">
          <span className="p-float-label">
            <Controller
              name="securityamount"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="securityamount">Security amount</label>
          </span>
        </div>

        <label htmlFor="noticeperiod">Notice Period ( In Months)*</label>
        <div className="field">
          <span className="">
            <Controller
              name="noticeperiod"
              control={control}
              rules={{ required: "Notice period is required." }}
              render={({ field, fieldState }) => (
                <Selector
                  id={field.name}
                  {...field}
                  value={field.value}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  items={["1", "2", "3"]}
                  onChange={(e) => {
                    return field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </span>
          {getFormErrorMessage("noticeperiod")}
        </div>
        <label htmlFor="rentincrement">Rent Increment*</label>
        <div className="flex align-items-center">
          <span className="">
            <Controller
              name="rentincrement"
              control={control}
              //   rules={{ required: "Notice period is required." }}
              render={({ field, fieldState }) => (
                <>
                  <Checkbox
                    id={field.name}
                    {...field}
                    inputId="rentincrement"
                    name="rentincrement"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    checked={field.value}
                  />
                  <label htmlFor="rentincrement" className="ml-2">
                    Increase Rent after 6 months
                  </label>
                </>
              )}
            />
          </span>
        </div>
        {basicDetail.rentincrement && (
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="increasepercentage"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label htmlFor="increasepercentage">Increase percentage</label>
            </span>
          </div>
        )}
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="otherclauses"
              control={control}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  onChange={(e) => field.onChange(e.target.value)}
                  rows={3}
                  cols={30}
                />
              )}
            />
            <label htmlFor="otherclauses">Any other clauses (Optional)</label>
          </span>
        </div>
      </div>
    </>
  );
};
