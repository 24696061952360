import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Header/Appointment/Appointment.css";
import styles from "./Login.module.css";

export const Register = () => {
  const role = [
    { name: "Admin", value: "admin" },
    { name: "User", value: "user" },
  ];
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [roles, setRoles] = useState("");
  const navigate = useNavigate();
  const defaultValues = {
    name: name,
    email: email,
    password: password,
    confPassword: confPassword,
    role: roles,
  };

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://mysro.in/post/users", {
        name: name,
        email: email,
        password: password,
        confPassword: confPassword,
        role: roles,
      });
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };

  return (
    <>
      <div className={`${styles.loginPage}`}>
        <div
          className={`flex align-content-center flex-wrap justify-content-center ${styles.height100}`}
        >
          <div className={`card ${styles.loginCard}`}>
            <h5 className={`text-center ${styles.heading}`}>Sign Up</h5>

            <form onSubmit={onSubmit} className="p-fluid">
              <p className="text-center">{message}</p>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Name is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="name"
                    className={classNames({ "p-error": errors.name })}
                  >
                    Name*
                  </label>
                </span>
              </div>
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message:
                          "Invalid email address. E.g. example@email.com",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({ "p-error": !!errors.email })}
                  >
                    Email*
                  </label>
                </span>
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        toggleMask={true}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password*
                  </label>
                </span>
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="confPassword"
                    control={control}
                    rules={{ required: "Confirm Password does not matched." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        toggleMask={true}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="confPassword"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Confirm Password*
                  </label>
                </span>
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        value={roles}
                        onChange={(e) => setRoles(e.target.value)}
                        options={role}
                        optionLabel="name"
                      />
                    )}
                  />
                  <label htmlFor="role">Role</label>
                </span>
              </div>
              <Button type="submit" label="Save" className="mt-2" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
