import React, { useRef } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
// import { FileUpload } from "primereact/fileupload";

export const FormPropertyDetails = ({
  onUpload,
  handleFileChange,
  handlePropertyTypeChange,
  // onFileSelect,
  // showUpload,
  // showDoc,
  fileNames,
  deleteFile,
}) => {
  // const [fileList, setFileList] = useState(null);
  // const [inputFields, setInputFields] = useState([
  //   { docNumber: "", docYear: "", subRegOffice: "", regAddress: "" },
  // ]);
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const {
    fields: docField,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "docDetails",
  });
  // const handleFileChange = (e) => {
  //   setFileList(e.target.files);
  // };

  const propertyType = [
    { name: "latest sale deed" },
    { name: "Property tax receipt" },
    { name: "EC" },
    { name: "Patta" },
    { name: "Others" },
  ];
  // const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  const watchFieldArray = watch("docDetails");
  const controlledFields = docField.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  // const result = watchFieldArray.filter((o) =>
  //   Object.values(o).some((v) => (v !== "") | undefined)
  // );
  // for (let i = 0; i < watchFieldArray.length; i++) {
  //   if (result.length > 0) {
  //     showUpload = false;
  //   }
  // }
  const getFormErrorMessage = (docName, index) => {
    return (
      errors["docDetails"] && (
        <small className="p-error">
          {errors["docDetails"][index][docName] &&
            errors["docDetails"][index][docName].message}
        </small>
      )
    );
  };
  const getPropertyTypemErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const hiddenFileInput = useRef(null);
  // if (selectedPropertyType.length > 0) {
  //   showDoc = false;
  // }
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  // if (docField.filter(value => value.some((a) => a).))
  // const toast = useRef(null);
  // const onUpload = () => {
  //   toast.current.show({
  //     severity: "info",
  //     summary: "Success",
  //     detail: "File Uploaded",
  //   });
  // };
  // const addMoreFields = () => {
  //   setInputFields([
  //     ...inputFields,
  //     { docNumber: "", docYear: "", subRegOffice: "", regAddress: "" },
  //   ]);
  // };

  // const removeInputFields = (index) => {
  //   const rows = [...inputFields];
  //   rows.splice(index, 1);
  //   setInputFields(rows);
  // };
  // const handleChange = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...inputFields];
  //   list[index][name] = value;
  //   setInputFields(list);
  // };
  return (
    <>
      {/* {showUpload && ( */}
      <>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name={`selectedPropertyType`}
              control={control}
              rules={{ required: "Select the Property types from the list" }}
              render={({ field, fieldState }) => (
                <MultiSelect
                  {...field}
                  className={classNames(
                    {
                      "p-invalid": fieldState.invalid,
                    },
                    "w-full"
                    // "md:w-20rem"
                  )}
                  // value={selectedPropertyType}
                  // onChange={(e) => setSelectedPropertyType(e.value)}
                  onChange={handlePropertyTypeChange}
                  options={propertyType}
                  optionLabel="name"
                  display="chip"
                  // placeholder="Select from the list"
                  maxSelectedLabels={3}
                />
              )}
            />
            <label htmlFor="selectedPropertyType">
              select a Property Type*
            </label>
          </span>
          {getPropertyTypemErrorMessage("selectedPropertyType")}
        </div>
        <div className="field">
          <span className="p-float-label">
            {/* <label htmlFor="fileUpload">Upload File*</label> */}
            <Button className="lightBtn" onClick={handleClick}>
              Upload a file
            </Button>
            <Controller
              name={`fileUpload`}
              control={control}
              rules={{ required: "File is required." }}
              render={({ field, fieldState }) => (
                <input
                  type="file"
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  ref={hiddenFileInput}
                  // ref={field.ref}
                  // files={selectedFile}
                  value={undefined}
                  onChange={handleFileChange}
                  multiple
                  style={{ display: "none" }}
                  accept=".pdf, .doc, .docx"
                />
              )}
            />
          </span>
        </div>
        <div>
          {fileNames.map((file, index) => (
            <div className="flex py-2 align-items-center ">
              <p className="m-0 pr-2">{file.name}</p>
              {/* <button type="button"> */}
              <i
                className="pi pi-times fontColor2dcdbc cursorPointer"
                title="Delete"
                onClick={() => deleteFile(index)}
              ></i>
              {/* </button> */}
            </div>
          ))}
        </div>
      </>
      {/* )} */}
      {/* <input
            type="file"
            name="fileUpload"
            // onChange={handleFileChange}
            multiple
          /> 
          <FileUpload
            id={field.name}
            {...field}
            className={classNames({
              "p-invalid": fieldState.invalid,
            })}
            url="https://mysro.in/uploads"
            // onUpload={onUpload}
            onChange={handleFileChange}
            customUpload
            uploadHandler={onUpload}
            multiple
            accept="image/*"
            maxFileSize={1000000}
            onSelect={onFileSelect}
            emptyTemplate={
              <p className="m-0">Drag and drop files to here to upload.</p>
            }
          />
          */}
      {/* {showDoc && ( */}
      <>
        {controlledFields.map((data, index) => {
          // const { docNumber, docYear, subRegOffice, regAddress } = data;
          return (
            <div className="p-fluid pt-5" key={index}>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name={`docDetails.${index}.docNumber`}
                    control={control}
                    rules={{ required: "Document number is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label htmlFor="docNumber">Document Number*</label>
                </span>
                {getFormErrorMessage("docNumber", index)}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name={`docDetails.${index}.docYear`}
                    control={control}
                    rules={{ required: "Document year is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label htmlFor="docYear">Document Year*</label>
                </span>
                {getFormErrorMessage("docYear", index)}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name={`docDetails.${index}.subRegOffice`}
                    control={control}
                    rules={{
                      required: "Sub registrar office is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label htmlFor="subRegOffice">Sub-registrar Office*</label>
                </span>
                {getFormErrorMessage("subRegOffice", index)}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Controller
                    name={`docDetails.${index}.regAddress`}
                    control={control}
                    rules={{ required: "Registrar Address is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label htmlFor="regAddress">Address*</label>
                </span>
                {getFormErrorMessage("regAddress", index)}
              </div>
              {/* {data.length !== 1 && ( */}
              <Button
                onClick={() => remove(index)}
                label=" Remove"
                type="button"
                className="block lightBlueBtn mb-2"
                icon={
                  <i className="pi pi-trash fontColor283552" title="Delete"></i>
                }
              />
              {/* )} */}
            </div>
          );
        })}

        <Button
          onClick={() =>
            append({
              docNumber: "",
              docYear: "",
              subRegOffice: "",
              regAddress: "",
            })
          }
          label=" Add More"
          type="button"
          className="lightBtn block mb-3"
          icon={<i className="pi pi-plus fontColor283552" title="Add More"></i>}
        />
      </>
      {/* )} */}
    </>
  );
};
