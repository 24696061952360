import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import "./Appointment.css";
import style from "./Form.module.css";
import { useSelector } from "react-redux";
import { selectedValue } from "./appointmentSlice";

export const AppointmentForm = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const count = useSelector(selectedValue);

  const defaultValues = {
    name: "",
    phone: "",
    city: "",
    serviceType: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    try {
      await axios.post("https://mysro.in/post/enquiry", {
        name: e.name,
        phone: e.phone,
        city: e.city,
        serviceType: e.serviceType,
      });
      setFormData(e);
      setShowMessage(true);
      reset();
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );

  useEffect(() => {
    setValue("serviceType", count);
  }, [count]);

  return (
    <>
      <Dialog
        visible={showMessage}
        onHide={() => setShowMessage(false)}
        position="top"
        footer={dialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <h5>Enquiry sent Successful!</h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
            Your enquiry is sent under name <b>{formData.name}</b>
          </p>
        </div>
      </Dialog>
      <p className="text-center">{message}</p>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="grid">
          <div className={`col-12 field ${style.topField}`}>
            <span className={`p-float-label ${style.floatLabel}`}>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Name is required." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="name"
                className={classNames({ "p-error": errors.name })}
              >
                Name*
              </label>
            </span>
            {getFormErrorMessage("name")}
          </div>
          <div className={`col-12 field ${style.topField}`}>
            <span className={`p-float-label ${style.floatLabel}`}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "Phone number is required.",
                  pattern: {
                    value: /^[0-9]{10}$/i,
                    message: "Invalid phone number address. E.g. 9000000000",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              <label
                htmlFor="phone"
                className={classNames({
                  "p-error": !!errors.phone,
                })}
              >
                Phone*
              </label>
            </span>
            {getFormErrorMessage("phone")}
          </div>
          <div className={`col-12 field ${style.topField}`}>
            <span className="">
              <Controller
                name="city"
                control={control}
                rules={{ required: "City is required." }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    {...field}
                    placeholder="Select City"
                    id={field.name}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    className={style.Dropdown}
                    options={[
                      "Alandur",
                      "Ambattur",
                      "Ambur",
                      "Avadi",
                      "Chennai",
                      "Coimbatore",
                      "Cuddalore",
                      "Dindigul",
                      "Erode",
                      "Hosur",
                      "Kancheepuram",
                      "Karaikkudi",
                      "Kumbakonam",
                      "Kurichi",
                      "Madavaram",
                      "Madurai",
                      "Nagapattinam",
                      "Nagercoil",
                      "Neyveli",
                      "Pallavaram",
                      "Pudukkottai",
                      "Rajapalayam",
                      "Salem",
                      "Tambaram",
                      "Thanjavur",
                      "Thoothukkudi",
                      "Tiruchirappalli",
                      "Tirunelveli",
                      "Tiruppur",
                      "Tiruvannamalai",
                      "Tiruvottiyur",
                      "Vellore",
                    ]}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("city")}
          </div>
          <div className={`col-12 field ${style.topField}`}>
            <span className="">
              <Controller
                name="serviceType"
                control={control}
                rules={{ required: "Service is required." }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    {...field}
                    id={field.name}
                    placeholder="Select Service type"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    className={style.Dropdown}
                    options={[
                      "Property Registration",
                      "mySRO Property Report",
                      "Rental Agreement",
                      "Legal Consultation",
                      "mySRO Valuation Report",
                    ]}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("service")}
          </div>
        </div>
        <Button
          type="submit"
          label="Book for Free Consultation"
          className={`mt-2 ${style.submitButton}`}
        />
      </form>
    </>
  );
};
