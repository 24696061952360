import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export const FormUserDetail = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <div className="p-fluid pt-5 formInputSpace">
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="name">Name*</label>
          </span>
          {getFormErrorMessage("name")}
        </div>
        <div className="field">
          <span className="p-float-label p-input-icon-right">
            <i className="pi pi-envelope" />

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address. E.g. example@email.com",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="email">Email*</label>
          </span>
          {getFormErrorMessage("email")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="mobile"
              control={control}
              rules={{ required: "Mobile number is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="mobile">Mobile*</label>
          </span>
          {getFormErrorMessage("mobile")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="address">Address</label>
          </span>
        </div>
      </div>
    </>
  );
};
