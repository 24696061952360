import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Home/Home";
import { About } from "./About/About";
import { Services } from "./Services/Services";
import { InnovationTechnology } from "./InnovationTechnology/InnovationTechnology";
import { FAQ } from "./FAQ/FAQ";
import { Careers } from "./Careers/Careers";
import { Contact } from "./Contact/Contact";
import { Dashboard } from "./PanelPages/Dashboard";
import { Login } from "./components/Login";
import { Users } from "./PanelPages/Users";
import { UserDoc } from "./PanelPages/UserDoc";
import { AddUser } from "./PanelPages/AddUser";
import { EditUser } from "./PanelPages/EditUser";
import { AddUserDoc } from "./PanelPages/AddUserDoc";
import { EditUserDoc } from "./PanelPages/EditUserDoc";
import { Register } from "./components/Register";
import { MysroPropertyReport } from "./Services/MysroPropertyReport/MysroPropertyReport";
import { PropertyRegistration } from "./Services/PropertyRegistration/PropertyRegistration";
import { RentalAgreement } from "./Services/RentalAgreement/RentalAgreement";
import { RentalAgreementForm } from "./Services/RentalAgreement/RentalAgreementForm";
import { MysroPropertyReportForm } from "./Services/MysroPropertyReport/MysroPropertyReportForm";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="pages">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route
              path="/services/mysro-property-report"
              element={<MysroPropertyReport />}
            />
            <Route
              path="/services/mysro-property-report/property-report-details"
              element={<MysroPropertyReportForm />}
            />
            <Route
              path="/services/property-registration"
              element={<PropertyRegistration />}
            />
            <Route
              path="/services/rental-agreement"
              element={<RentalAgreement />}
            />
            <Route
              path="/services/rent-agreement"
              element={<RentalAgreementForm />}
            />
            <Route
              path="/innovation-and-technology"
              element={<InnovationTechnology />}
            />
            <Route path="/careers" element={<Careers />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/add" element={<AddUser />} />
            <Route path="/users/edit/:id" element={<EditUser />} />
            <Route path="/usersdoc" element={<UserDoc />} />
            <Route path="/usersdoc/add" element={<AddUserDoc />} />
            <Route path="/usersdoc/edit/:id" element={<EditUserDoc />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
