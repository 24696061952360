import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import style from "./TestimonialSection.module.css";

export const TestimonialSection = (props) => {
  const options = {
    items: 3,
    margin: 20,
    loop: true,
    autoplay: true,
    responsiveClass: true,
    autoplayTimeout: 15000,
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="section-pt position-relative">
      {/* <div className="container">
        <div className="grid">
          <div className="md:col-7">
            <img
              src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
              alt="test"
            />
          </div>
          <div className="md:col-5">
            <div className="title-section mb-3 text-center">
              <span className={style.subTitle}>TESTIMONIALS</span>
              <h3 className={style.title}>
                Don't Believe us Check
                <span className="text-primary"> Clients</span> word
              </h3>
              <p className={`mb-6" ${style.happyCustomer}`}>
                More over 2500 happy customer
              </p>
              <OwlCarousel
                className="owl-main  owl-theme"
                items={1}
                loop
                autoplay
                autoplayTimeout={15000}
                nav={true}
                navText={["< Prev", "Next >"]}
                navClassNclassName={[
                  `owl-prev ${style.owlPrev}`,
                  `owl-next ${style.owlNext}`,
                ]}
                dots={false}
                navContainerClassNclassName={`owl-nav ${style.owlNav}`}
              >
                <div className="item">
                  <div className="caption d-md-block">
                    <div className="display">
                      <img className={style.quoteIcon} src={quote} alt="test" />
                    </div>
                    <div className={style.content}>
                      Pleasure rationally encounter consequences that are
                      extremely painful. Nor again is there anyone who loves or
                      pursues or desires maintain
                    </div>
                    <div className={style.avaterInfo}>
                      <p>David Loyed</p>
                      <span>CEO, Omex</span>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="caption d-md-block">
                    <div className="display">
                      <img className={style.quoteIcon} src={quote} alt="test" />
                    </div>
                    <div className={style.content}>
                      Pleasure rationally encounter consequences that are
                      extremely painful. Nor again is there anyone who loves or
                      pursues or desires maintain
                    </div>
                    <div className={style.avaterInfo}>
                      <p>David Loyed</p>
                      <span>CEO, Omex</span>
                    </div>
                  </div>
                </div>
              </OwlCarousel>

              {/* <a href="/about" className="btn btn-lg btn-dark btn-hover-dark">
                Know More
              </a> 
            </div>
          </div>
        </div>
      </div> */}

      <div className={style.bg_img} data-bg="assets/img/team/team-bg.jpg">
        <div className="container">
          <div className="grid align-items-center">
            <div className="md:col-4">
              <div className={style.section_title}>
                <h2>Property Owners Love Us</h2>
                <span className={style.hrLines}>
                  <i className={`pi pi-comments ${style.hrLine}`}></i>
                </span>
              </div>
            </div>

            <div className="md:col-8">
              <OwlCarousel
                className="owl-main owl-theme"
                navText={["< Prev", "Next >"]}
                navClass={[
                  `owl-prev ${style.owlPrev}`,
                  `owl-next ${style.owlNext}`,
                ]}
                navContainerClass={`owl-nav ${style.owlNav}`}
                dotsClass={`owl-dots ${style.owlDots}`}
                dotClass={`owl-dot ${style.owlDot}`}
                {...options}
              >
                <div className={`item ${style.item}`}>
                  <div className={"caption d-md-block"}>
                    <div className="display">
                      <img
                        className={style.quoteIcon}
                        src="/images/quote.png"
                        alt="test"
                      />
                    </div>
                    <div className={style.content}>
                      Pleasure rationally encounter consequences that are
                      extremely painful. Nor again is there anyone who loves or
                      pursues or desires maintain
                    </div>
                    <div className={style.avaterInfo}>
                      <p>David Loyed</p>
                      <span>CEO, Omex</span>
                    </div>
                  </div>
                </div>
                <div className={`item ${style.item}`}>
                  <div className="caption d-md-block">
                    <div className="display">
                      <img
                        className={style.quoteIcon}
                        src="/images/quote.png"
                        alt="test"
                      />
                    </div>
                    <div className={style.content}>
                      Pleasure rationally encounter consequences that are
                      extremely painful. Nor again is there anyone who loves or
                      pursues or desires maintain
                    </div>
                    <div className={style.avaterInfo}>
                      <p>David Loyed</p>
                      <span>CEO, Omex</span>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
