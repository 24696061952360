import React from "react";

export const PreviewDetails = ({ values, fileView }) => {
  const { name, email, mobile, address, docDetails, fileUpload } = values;
  return (
    <>
      <p>
        <strong>Name :</strong> {name}
      </p>
      <p>
        <strong>Email :</strong> {email}
      </p>
      <p>
        <strong>Mobile :</strong> {mobile}
      </p>
      <p>
        <strong>Address :</strong> {address}
      </p>
      {docDetails.map((list, index) => {
        return (
          <div key={index}>
            <p>
              <strong>Document Number :</strong> {list.docNumber}
            </p>
            <p>
              <strong>Document Year :</strong> {list.docYear}
            </p>
            <p>
              <strong>sub-registrar Office :</strong> {list.subRegOffice}
            </p>
            <p>
              <strong>Address :</strong> {list.regAddress}
            </p>
          </div>
        );
      })}
      <br />
      <strong>Attached Files :</strong>
      {fileView.map((doc, index) => {
        // return <img className="image" src={doc} alt="" key={index} />;
        return <p key={index}>{doc}</p>;
      })}
    </>
  );
};
