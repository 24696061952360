import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

export const UserDocList = () => {
  const [userList, setUserList] = useState([]);
  const getUserDoc = async () => {
    const response = await axios.get("https://mysro.in/get/usersdoc");
    setUserList(response.data);
  };
  const deleteUserList = async (docId) => {
    await axios.delete(`https://mysro.in/delete/usersdoc/${docId}`);
    getUserDoc();
  };
  useEffect(() => {
    getUserDoc();
  }, []);
  return (
    <div>
      <h1>Users Documents</h1>
      <h2>List of Users Documents</h2>
      <Link to="/usersdoc/add">Add User</Link>
      <table className="table">
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Price</th>
            <th>Created by</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((list, index) => (
            <tr key={list.uuid}>
              <td>{index + 1}</td>
              <td>{list.name}</td>
              <td>{list.price}</td>
              <td>{list.user.name}</td>
              <td>
                <Link to={`/usersdoc/edit/${list.uuid}`}>Edit</Link>
              </td>
              <td>
                <Button
                  className="button"
                  onClick={() => deleteUserList(list.uuid)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
