import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import "../MysroPropertyReport/MysroPropertyReport.css";
import styles from "./PropertyRegistration.module.css";
import { useDispatch } from "react-redux";
import { setPropertyType } from "../../Header/Appointment/appointmentSlice";
import { AppointmentForm } from "../../Header/Appointment/Form";
import PRJourney from "./PRjourney.json";
import PRFAQs from "./PRFAQs.json";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";

export const PropertyRegistration = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    dispatch(setPropertyType("Property Registration"));
  }, []);
  return (
    <>
      <Header />
      <div className="bge4f7f9 position-relative">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <h1 className={styles.heading1}>Your mySRO Journey </h1>
                  <div className={styles.timelineContainer}>
                    <ul className={styles.tl}>
                      {PRJourney.PRjourney.map((data, index) => {
                        return (
                          <li className={styles.wrapperBox} key={index + "PR"}>
                            <div className={styles.itemIcon}>
                              <img
                                src={data.image}
                                alt={data.heading}
                                className={styles.boxTopIcon}
                              />
                            </div>
                            <div className={styles.itemText}>
                              <h4>{data.heading}</h4>
                              <div className={styles.box}>
                                <div className={styles.innerBox}>
                                  <p className={styles.numbers}>{index + 1}</p>
                                  <div className={styles.boxContent}>
                                    <p>{data.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  {/* {PRJourney.PRjourney.map((data, index) => {
                    return (
                      <div className={styles.wrapperBox} key={index + "PR"}>
                        <div className="text-center">
                          <img
                            src={data.image}
                            alt={data.heading}
                            className={styles.boxTopIcon}
                          />
                        </div>
                        <div className={styles.box}>
                          <div className={styles.innerBox}>
                            <p className={styles.numbers}>{index + 1}</p>
                            <div className={styles.boxContent}>
                              <h4>{data.heading}</h4>
                              <p>{data.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                     );
                  })} } */}
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative">
                <div className="howItWorksBox">
                  <div className="howItWorks">
                    <h3 className="fontColor283552 text-center">
                      Book your Appointment
                    </h3>
                    <AppointmentForm />
                    {/* <ul className="">
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">
                            One stop solution for property registration
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Submit details online</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Our experts will call you</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">
                            Dedicated client relationship executive assigned to
                            you
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">
                            Preparation and validation of draft deeds
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">Slot fixing and Live tracking</p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">
                            On-Field assistance at Sub-Registrar office
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="flex align-items-center">
                          <img
                            className="logo"
                            src="https://cdn.spark.app/media/housingcom/icon/1.svg"
                            alt=" feature logo"
                          />
                          <p className="pl-2">
                            Even more with mySRO's Assurance
                          </p>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                  <div className="RequestReport">
                    <h5
                      className={`fontColor283552 text-left m-0 ${styles.heading}`}
                    >
                      mySRO Assurance:
                    </h5>
                    <ul className={styles.list}>
                      <li>Zero sign-up/consultation fees</li>
                      <li>100 percent legal, Safe and transparent</li>
                      <li>End-to-End process at your doorstep</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <div className={styles.section_title}>
                    <h1 className={styles.heading1}>Type of deeds</h1>
                    <span className={styles.hrLines}>
                      <img
                        src="/images/logoIcon.png"
                        alt="mySRO icon"
                        className={styles.headingIcon}
                      />
                    </span>
                  </div>
                  <div className={styles.deedHeading}>
                    <div className={styles.deedlist}>
                      <i
                        className={`pi pi-angle-double-right ${styles.deedlistIcon}`}
                      ></i>
                      <div>
                        <h4>Sale Deed</h4>

                        <p>
                          A legal document signifying actual transfer of
                          ownership of a property, including the rights,
                          liabilities and claims associated with the property.
                          The sale deed is valid only upon registering at a
                          sub-registrar office, and the buyer is responsible for
                          paying the associated stamp duty.
                        </p>
                      </div>
                    </div>
                    <div className={styles.deedlist}>
                      <i
                        className={`pi pi-angle-double-right ${styles.deedlistIcon}`}
                      ></i>
                      <div>
                        <h4>Sale Agreement</h4>
                        <p>
                          An executing contract promising to transfer property
                          ownership in the future, with the seller bearing the
                          risk until the sale is complete. The agreement
                          outlines the sale terms and sets the stage for a
                          future sale, with conditions and legal implications.
                        </p>
                      </div>
                    </div>
                    <div className={styles.deedlist}>
                      <i
                        className={`pi pi-angle-double-right ${styles.deedlistIcon}`}
                      ></i>
                      <div>
                        <h4>MoDT</h4>
                        <p>
                          Memorandum of deposit of title deed is a document
                          where borrower voluntarily despoits property's title
                          documents with the lender (Banks, in most cases). This
                          document acts as proof that the lender can recover
                          debts if the borrower defaults on loan payments.
                        </p>
                      </div>
                    </div>
                    <div className={styles.deedlist}>
                      <i
                        className={`pi pi-angle-double-right ${styles.deedlistIcon}`}
                      ></i>
                      <div>
                        <h4>PoA</h4>
                        <p>
                          Power of Attorney is a legal document that allows the
                          property owner (Principal) to authorize a designated
                          person (Agent) to act on their behalf in various
                          property-related actions including selling, modifying
                          and developing as specified in the PoA document
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.TableWrapper}>
                    <button
                      className={`smoothButton ${styles.clickAction}`}
                      type="button"
                    >
                      Other registrations at mySRO
                    </button>
                    <ul
                      className={`fadeindown animation-duration-500 ${styles.tableList}`}
                    >
                      <li>Gift deed</li>
                      <li> Exchange deed</li>
                      <li>Simple Mortgage</li>
                      <li>Mortgage with possession</li>
                      <li>Cancellation</li>
                      <li>Partition among family members</li>
                      <li>Partition among Non family members</li>
                      <li>Settlement Deed</li>
                      <li>Partnership deed</li>
                      <li>Release deed</li>
                      <li>Lease deed</li>
                      <li>Agreement on Construction of building</li>
                      <li>Declaration of Trust</li>
                      <li>Receipt</li>
                      <li>Sale Certificate</li>
                    </ul>
                    {/* <table
                      className={`fadeindown animation-duration-500 ${styles.table}`}
                    >
                      <thead>
                        <tr>
                          <th>Test</th>
                          <th>Test</th>
                          <th>Test</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Test</td>
                          <td>Test</td>
                          <td>Test</td>
                        </tr>
                        <tr>
                          <td>Test</td>
                          <td>Test</td>
                          <td>Test</td>
                        </tr>
                      </tbody>
                    </table> */}
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="bge4f7f9 position-relative py-5">
        <div className="container">
          <div className="boxSpacing">
            <div className="grid m-0">
              <div className="col-6 p-0">
                <div className="">
                  <h1 className={styles.heading1}>Frequently asked question</h1>
                  <div className="">
                    <Accordion
                      activeIndex={activeIndex}
                      onTabChange={(e) => setActiveIndex(e.index)}
                      className="faqTabs"
                    >
                      {PRFAQs.PRFAQs.map((list, index) => {
                        return (
                          <AccordionTab
                            header={list.question}
                            headerClassName={styles.accordionHeader}
                            key={"PRFAQs" + index}
                          >
                            <p>{list.ans}</p>
                          </AccordionTab>
                        );
                      })}
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-5 relative"></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="section-pt relative" id="requestForReport">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="mb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-4">
              <h3 className={styles.dont}>
                Conventional Property Registration
              </h3>
              <ul className={styles.dontList}>
                <li>Fraudulent activities</li>
                <li>Prone to mistakes/errors in drafting of documents</li>
                <li>
                  Over pricing or Don't know how much to pay for the service
                </li>
                <li>Accessibility and mobility issues</li>
                <li>Lots of doubts and no clarity</li>
                <li>Complex process</li>
                <li>No professionalism</li>
              </ul>
            </div>
            <div className="col-4">
              <img src="/images/icon-1.png" alt="mySRO" />
            </div>
            <div className="col-4">
              <h3 className={styles.do}>mySRO Property Registration</h3>
              <ul className={styles.doList}>
                <li>100% legal and safe</li>
                <li>Error-free drafting of documents</li>
                <li>Lowest charges</li>
                <li>Property Registration at your doorstep</li>
                <li>Dedicated client Executive</li>
                <li>Simple process with Minimal Turn-around time</li>
                <li>Live-tracking of documents</li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};
