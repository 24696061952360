import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import FAQS from "../JSON/faqs";
import "./FAQ.css";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>FAQs</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative">
        <div className="container">
          <Accordion
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            className="faqTabs"
          >
            {FAQS.faqs.map((item, i) => (
              <AccordionTab header={item.heading} key={i}>
                <p>{item.description}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </div>
      </div>
      <Footer />
    </>
  );
};
