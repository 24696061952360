import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginUser, reset } from "../features/authSlice";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import "../Header/Appointment/Appointment.css";
import styles from "./Login.module.css";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const defaultValues = {
    email: email,
    password: password,
  };

  const {
    control,
    formState: { errors },
  } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user || isSuccess) {
      navigate("/dashboard");
    }
    dispatch(reset());
  }, [user, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  return (
    <>
      <div className={`${styles.loginPage}`}>
        <div
          className={`flex align-content-center flex-wrap justify-content-center ${styles.height100}`}
        >
          <div className={`card ${styles.loginCard}`}>
            <h5 className={`text-center ${styles.heading}`}>Sign In</h5>
            {/* <form onSubmit={Auth} className="box">
              {isError && <p className="text-center">{message}</p>}
              <div className="field mt-5">
                <label className="label">Email or Username</label>
                <div className="controls">
                  <input
                    type="text"
                    className="input"
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <label className="label">Password</label>
                <div className="controls">
                  <input
                    type="password"
                    className="input"
                    placeholder="******"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field mt-5">
                <button className="button is-success is-fullwidth">
                  {isLoading ? "Loading..." : "Login"}
                </button>
              </div>
            </form> */}
            <form onSubmit={Auth} className="p-fluid">
              {isError && <p className="text-center">{message}</p>}
              <div className={`field ${styles.spaceBottom}`}>
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message:
                          "Invalid email address. E.g. example@email.com",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({ "p-error": !!errors.email })}
                  >
                    Email*
                  </label>
                </span>
              </div>
              <div className={`field ${styles.spaceBottom}`}>
                <span className="p-float-label">
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name}
                        {...field}
                        feedback={false}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        toggleMask={true}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    Password*
                  </label>
                </span>
              </div>

              <Button type="submit" className="mt-2 text-center block">
                {isLoading ? "Loading..." : "Login"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
