import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout, reset } from "../features/authSlice";
import "../Header/Header.css";
import styles from "./PanelNavbar.module.css";
import { Button } from "primereact/button";

export const PanelNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user } = useSelector((state) => state.auth);
  const logout = () => {
    dispatch(Logout());
    dispatch(reset());
    navigate("/login");
  };
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const Close = () => setClick(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".navbar");
    const scrollTop = window.scrollY;
    scrollTop >= 150
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()}>
        <nav
          className={`navbar ${styles.panelNavbar}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="nav-container">
            <NavLink exact="true" to="/" className="nav-logo">
              mySRO
            </NavLink>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  exact="true"
                  to="/"
                  activeclassname="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  Home
                </NavLink>
              </li>

              <li className="appButton">
                <Button label="Logout" onClick={logout} />
              </li>
            </ul>
            <div className="nav-icon" onClick={handleClick}>
              <i className={click ? "pi pi-times" : "pi pi-bars"}></i>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
