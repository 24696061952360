import React from "react";
import { PanelNavbar } from "../components/PanelNavbar";
import { Sidebar } from "../components/Sidebar";
import styles from "./Layout.module.css";

export const Layout = ({ children }) => {
  return (
    <>
      <PanelNavbar />
      <div className="">
        <div className={`grid ${styles.borderTop}`}>
          <div className={`col-2 ${styles.bgGray}`}>
            <Sidebar />
          </div>
          <div className="col-10">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};
