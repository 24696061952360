import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import styles from "./RentalAgreement.module.css";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const RentalAgreement = () => {
  const cities = ["aa", "bb"];
  const [city, setCity] = useState("");
  const defaultValues = {
    state: "",
    city: "",
  };
  const history = useNavigate();

  const [state, setState] = useState({
    state: "Tamil Nadu",
    city: "",
  });
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, control, setValue } = methods;
  const onSubmit = () => {
    history("/services/rent-agreement", { state: state });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    setValue("state", "Tamil Nadu");
  }, [setValue]);

  return (
    <>
      <Header />
      <div className="position-relative">
        <div className="container-fluid">
          <div className="bg26567e boxSpacing">
            <div className="container">
              <div className="grid m-0">
                <div className="col-5 p-0">
                  <div className={styles.boxInfo}>
                    <h1>mySRO Assurance</h1>
                    <ul className="list-disc pl-5">
                      <li>Simple and Easy</li>
                      <li>Super-fast and Instant agreements</li>
                      <li>100% legally valid</li>
                      <li>On demand Door step delivery </li>
                    </ul>
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-5">
                  <div className={styles.formbox}>
                    <div className={styles.formboxSpacing}>
                      <h3 className="text-center">Create New Agreement</h3>

                      <div className="">
                        <FormProvider {...methods}>
                          <form
                            className="p-fluid"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="field">
                              <span className="p-float-label">
                                <Controller
                                  name="state"
                                  control={control}
                                  render={({ field }) => (
                                    <InputText
                                      {...field}
                                      id={field.name}
                                      // defaultValue={"Tamil Nadu"}
                                      value={"Tamil Nadu"}
                                      disabled={true}
                                    />
                                  )}
                                />
                                <label htmlFor="state">State</label>
                              </span>
                            </div>
                            <div className="field">
                              <span className="p-float-label">
                                <Controller
                                  name="city"
                                  control={control}
                                  render={({ field }) => (
                                    <Dropdown
                                      {...field}
                                      id={field.name}
                                      value={city}
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                        handleInputChange(e);
                                        setValue("city", e.target.value);
                                      }}
                                      options={cities}
                                    />
                                  )}
                                />
                                <label htmlFor="city">City</label>
                              </span>
                            </div>
                            <Button
                              label="Create your Rent Agreement now"
                              type="submit"
                              className="mt-2"
                            />
                          </form>
                        </FormProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative" id="requestForReport">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="mb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
            </div>
          </div>
          <div className="grid"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};
