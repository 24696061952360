import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import "./FormStyles.css";
import { Selector } from "../../CommonComponent/Selector/Selector";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

export const PropertyDetails = ({ basicDetail, ...props }) => {
  const {
    control,
    formState: { errors },
    setValue,
    resetField,
  } = useFormContext();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const [floor, setFloor] = useState("");
  const floors = [
    "G",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];
  useEffect(() => {
    // setValue("city", props.city);
    // setValue("state", props.state);
    // setValue("stamp", 100);
  }, [props.city, props.state]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const sameAddressAsLandlord = (value) => {
    if (value === true) {
      setValue("propertyaddress", basicDetail.landlordDetails[0].address);
      setValue("propertycity", basicDetail.landlordDetails[0].city);
      setValue("propertystate", basicDetail.landlordDetails[0].state);
      setValue("propertypincode", basicDetail.landlordDetails[0].pincode);
    } else {
      console.log(value);
      resetField("propertyaddress");
      resetField("propertycity");
      resetField("propertystate");
      resetField("propertypincode");
    }
  };
  return (
    <>
      <div className="p-fluid pt-5 formInputSpace">
        <label htmlFor="name">Floor No.*</label>
        <div className="field">
          <span className="">
            <Controller
              name="floor"
              control={control}
              rules={{ required: "Name is required." }}
              render={({ field, fieldState }) => (
                <>
                  {/* <Selector
                    id={field.name}
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                    items={["G", "1", "2", "3"]}
                  /> */}
                  <Dropdown
                    {...field}
                    id={field.name}
                    // value={floor}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      //   setFloor(e.target.value);
                      //   setValue("floor", e.target.value);
                    }}
                    options={floors}
                  />
                </>
              )}
            />
          </span>
          {getFormErrorMessage("floor")}
        </div>
        <label htmlFor="bhk">Choose BHK*</label>
        <div className="field">
          <span className="">
            <Controller
              name="bhk"
              control={control}
              rules={{ required: "Name is required." }}
              render={({ field, fieldState }) => (
                <Selector
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  items={[
                    "1 RK",
                    "1 BHK",
                    "1.5 BHK",
                    "2 BHK",
                    "2.5 BHK",
                    "3 BHK",
                    "3.5 BHK",
                    "4 BHK",
                  ]}
                  ref={null}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              )}
            />
          </span>
          {getFormErrorMessage("bhk")}
        </div>
        <label htmlFor="furnishing">Furnishing </label>
        <div className="field ">
          <span className="">
            <Controller
              name="furnishing"
              control={control}
              rules={{ required: "Please select any one" }}
              render={({ field, fieldState }) => (
                <div className="card flex justify-content-center">
                  <div className="flex flex-wrap gap-3">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="unfurnished"
                        name="unfurnished"
                        value={"Unfurnished"}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        checked={field.value === "Unfurnished"}
                      />
                      <label htmlFor="unfurnished" className="ml-2">
                        Unfurnished
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="semifurnished"
                        name="semifurnished"
                        value={"Semi-furnished"}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        checked={field.value === "Semi-furnished"}
                      />
                      <label htmlFor="semifurnished" className="ml-2">
                        Semi-furnished
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="fullyfurnished"
                        name="fullyfurnished"
                        value={"Fully Furnished"}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        checked={field.value === "Fully Furnished"}
                      />
                      <label htmlFor="fullyfurnished" className="ml-2">
                        Fully Furnished
                      </label>
                    </div>
                  </div>
                </div>
              )}
            />
          </span>
        </div>
        <div className="flex align-items-center">
          <Controller
            name="sameAddressAsLandlord"
            control={control}
            render={({ field, fieldState }) => (
              <Checkbox
                inputId="sameAddressAsLandlord"
                id={field.name}
                {...field}
                // value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  sameAddressAsLandlord(e.target.checked);
                }}
                checked={field.value}
              />
            )}
          />
          <label htmlFor="sameAddressAsLandlord" className="ml-2">
            Same as Landlord Address
          </label>
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="houseno"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="houseno">House No.</label>
          </span>
          {getFormErrorMessage("houseno")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="propertyaddress"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="propertyaddress">Property Address</label>
          </span>
          {getFormErrorMessage("propertyaddress")}
        </div>

        <div className="field">
          <span className="p-float-label">
            <Controller
              name="propertycity"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="city">City</label>
          </span>
          {getFormErrorMessage("propertycity")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="propertystate"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="state">State</label>
          </span>
          {getFormErrorMessage("propertystate")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="propertypincode"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="pincode">Pin code</label>
          </span>
          {getFormErrorMessage("propertypincode")}
        </div>
      </div>
    </>
  );
};
