import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";

import { MysroFormReport } from "./MysroFormReport";
import "./MysroPropertyReport.css";
import "../Service.css";
import { useLocation } from "react-router-dom";

export const MysroPropertyReportForm = (props) => {
  const location = useLocation();
  return (
    <>
      <Header />
      <div className="section-pt relative pb-5" id="requestForReport">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                {/* <span className="sub-title">FEATURES</span> */}
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="mb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col-6 col-offset-3">
              <MysroFormReport
                inputName={location.state ? location.state.name : ""}
                inputEmail={location.state ? location.state.email : ""}
                inputPhone={location.state ? location.state.phone : ""}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-pt relative" id="requestForReport">
            <div className="container">
            <div className="grid">
                <div className="col-8 col-offset-2">
                <div className="title-section mb-3 text-center">
                    <span className="sub-title">FEATURES</span>
                    <h3 className="title">
                    When is the right time for Property Legal Title Check?
                    </h3>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="flexbox">
            <div className="flex flexbottom">
            <h2 className="stepNumber">01</h2>
            <div className="textbox">
                <h4 className="m-0">Finalize a property</h4>
                <p>
                Find the property you want to buy & negotiate on the final price
                </p>
            </div>
            <div className="imgbox">
                <img
                src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
                alt=""
                />
            </div>
            </div>
            <div className="flex flextop">
            <div className="imgbox">
                <img src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126876_960_720.png" />
            </div>
            <h2 className="stepNumber">02</h2>
            <div className="textbox">
                <h4 className="m-0">Documents verification</h4>
                <p>Let experts verify the documents before you close the deal</p>
            </div>
            </div>
            <div className="flex flexbottom">
            <h2 className="stepNumber">03</h2>
            <div className="textbox">
                <h4 className="m-0">Apply for a Home Loan</h4>
                <p>After the verification of documents, apply for a home loan</p>
            </div>
            <div className="imgbox">
                <img
                src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126878_960_720.png"
                alt=""
                />
            </div>
            </div>
            <div className="flex flextop">
            <div className="imgbox">
                <img
                src="https://cdn.pixabay.com/photo/2017/01/10/23/01/vector-1970471_960_720.png"
                alt=""
                />
            </div>
            <h2 className="stepNumber">04</h2>
            <div className="textbox">
                <h4 className="m-0">Move in</h4>
                <p>Take complete possession of the property</p>
            </div>
            </div>
            <div className="flex flexbottom">
            <h2 className="stepNumber">05</h2>
            <div className="textbox">
                <h4 className="m-0">Finalize a property</h4>
                <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure,
                illo.
                </p>
            </div>
            <div className="imgbox">
                <img
                src="https://cdn.pixabay.com/photo/2017/03/08/14/20/flat-2126884_960_720.png"
                alt=""
                />
            </div>
            </div>
        </div> */}
      <Footer />
    </>
  );
};
