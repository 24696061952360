import React from "react";
import "./AboutSection.css";
import AboutUs from "../../JSON/about";

export const AboutSection = (props) => {
  return (
    <div className="section-pt position-relative">
      <div className="container">
        <div className="grid">
          <div className="col-8 col-offset-2">
            <div className="title-section mb-3 text-center">
              <h3 className="title">
                We are <span className="text-primary">different</span>{" "}
                because...
              </h3>
              <p className="mb-6">
                We care for your dream property bought out of your hard-earned
                life savings!
              </p>
              {/* <a href="/about" className="btn btn-lg btn-dark btn-hover-dark">
                Know More
              </a> */}
            </div>
          </div>
        </div>

        <div className="grid centerdivPadding itemCenter">
          <div className="md:col-5">
            <div>
              <h3 className="mySROway_heading">Traditional Way</h3>
            </div>
          </div>
          <div className="md:col-2 bgf1f8ff topBorderRadius">
            <div className="text-center">
              <span className="mySROway_space"></span>
            </div>
          </div>
          <div className="md:col-5">
            <div>
              <h3 className="mySROway_heading">mySRO way</h3>
            </div>
          </div>
          {AboutUs.homeAboutUs.map((list, index) => (
            <React.Fragment key={index}>
              <div className="md:col-5">
                <div>
                  <p
                    className={`mySROway_para color2dcdbc leftBorderRadius ${list.color1}`}
                  >
                    {list.heading1}
                  </p>
                </div>
              </div>
              <div className="md:col-2 bgf1f8ff">
                <div className="text-center">
                  <img
                    src={list.icon}
                    alt="mySRO Way"
                    className="mySROway_icon"
                  />
                </div>
              </div>
              <div className="md:col-5">
                <div>
                  <p
                    className={`mySROway_para color2dcdbc rightBorderRadius ${list.color2}`}
                  >
                    {list.heading2}
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className="md:col-5">
            <div></div>
          </div>
          <div className="md:col-2 bgf1f8ff bottomBorderRadius">
            <div className="text-center">
              <span className="mySROway_space"></span>
            </div>
          </div>
          <div className="md:col-5">
            <div></div>
          </div>
        </div>
        {/* <div className="grid centerdivPadding">
          {AboutUs.aboutUs.map((item, i) => (
            <div className="md:col-4" key={i}>
              <Card className="featureCard h-full">
                <span className="card-shape card-shape-light"></span>
                <span className="card-shape card-shape-dark"></span>
                <div className="serviceIcon">
                  <img className="" src={item.icon} alt="feature logo" />
                </div>
                <h4 className="title my-3">
                  <a href="/about">{item.heading}</a>
                </h4>
                <ul className="list-disc pl-5">
                  {item.description.slice(0, 2).map((list, index) => (
                    <li key={index} className="pb-4">
                      {list.list}
                    </li>
                  ))}
                </ul> 
              </Card>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};
