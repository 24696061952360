import React, { useState } from "react";
import styles from "./RentalAgreement.module.css";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { useLocation } from "react-router-dom";
import { FormUserDetail } from "./FormUserDetail";
import { LandlordDetails } from "./LandlordDetails";
import { TenantDetails } from "./TenantDetails";
import { PropertyDetails } from "./PropertyDetails";
import { ContractDetails } from "./ContractDetails";
import { AddItemList } from "./AddItemList";
import { PreviewAgreement } from "./PreviewAgreement";

export const RentalAgreementForm = (props) => {
  let location = useLocation();
  const { state, city } = (location && location.state) || {};
  const items = [
    {
      label: "Help us know you better",
    },
    {
      label: "Landlord Details",
    },
    {
      label: "Tenant Details",
    },
    {
      label: "Property Details",
    },
    {
      label: "Contract Details",
    },
    {
      label: "Item List",
    },
    {
      label: "Preview data",
    },
  ];
  const [step, setStep] = useState(0);
  const defaultValues = {
    name: "",
    email: "",
    mobile: "",
    state: "",
    city: "",
    stamp: "",
    iam: "",
    landlordDetails: [
      {
        name: "",
        parentname: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        mobile: "",
        email: "",
        panid: "",
        aadhaar: "",
      },
    ],
    tenantDetails: [
      {
        name: "",
        parentname: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        mobile: "",
        email: "",
        panid: "",
        aadhaar: "",
      },
    ],
    addItemList: [
      {
        name: "Fans",
        quantity: 0,
      },
      {
        name: "Chair",
        quantity: 0,
      },
      {
        name: "Sofa",
        quantity: 0,
      },
      {
        name: "Table",
        quantity: 0,
      },
      {
        name: "Bed",
        quantity: 0,
      },
      {
        name: "Wooden",
        quantity: 0,
      },
      {
        name: "cupboards",
        quantity: 0,
      },
      {
        name: "Dining Table",
        quantity: 0,
      },
      {
        name: "Air Conditioners",
        quantity: 0,
      },
      {
        name: "Air Coolers",
        quantity: 0,
      },
      {
        name: "Geysers",
        quantity: 0,
      },
      {
        name: "TV",
        quantity: 0,
      },
      {
        name: "Fridge",
        quantity: 0,
      },
      {
        name: "Oven",
        quantity: 0,
      },
      {
        name: "Washing Machine",
        quantity: 0,
      },
      {
        name: "Study Table",
        quantity: 0,
      },
      {
        name: "Gas stove",
        quantity: 0,
      },
      {
        name: "Modular Kitchen",
        quantity: 0,
      },
      {
        name: "Chimney",
        quantity: 0,
      },
    ],
  };

  const cases = () => {
    switch (step) {
      case 0:
        return (
          <FormUserDetail
            state={state}
            city={city}
            basicDetail={watchAllFields}
          />
        );
      case 1:
        return <LandlordDetails basicDetail={watchAllFields} />;
      case 2:
        return <TenantDetails basicDetail={watchAllFields} />;
      case 3:
        return <PropertyDetails basicDetail={watchAllFields} />;
      case 4:
        return <ContractDetails basicDetail={watchAllFields} />;
      case 5:
        return <AddItemList />;
      case 6:
        return <PreviewAgreement previewData={watchAllFields} />;

      default:
        return "Unknown step";
    }
  };
  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, trigger, watch } = methods;
  const watchAllFields = watch();
  const nextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setStep((step) => step + 1);
  };
  const prevStep = () => {
    setStep((step) => step - 1);
  };
  console.log(watchAllFields);

  const onSubmit = async (e) => {
    // e.preventDefault();
  };
  return (
    <>
      {/* <Header /> */}
      <div className={`position-relative ${styles.vh100} ${styles.parentDiv}`}>
        <div className={`container-fluid ${styles.plr20}`}>
          <div className=" boxSpacing">
            <div className="container-fluid">
              <div className="grid m-0">
                {step !== items.length - 1 && (
                  <div className={`col-4 bg26567e ${styles.borderRadius12}`}>
                    {/* <Steps
                    model={items}
                    activeIndex={step}
                    className={`active${step + 1} ${styles.stepList}`}
                  /> */}
                    <div className={`${styles.stepList} step-${step}`}>
                      <ul>
                        {items.map((a, index) => (
                          <li
                            className={`p-steps-item ${
                              step === index ? styles.activeStep : ""
                            }
                          ${
                            step !== items.length && step !== index
                              ? styles.disabled
                              : ""
                          }`}
                            key={index}
                          >
                            <p>
                              <span className={styles.stepsNumber}>
                                {index + 1}
                              </span>
                              <span className={styles.stepsTitle}>
                                <span>Step {index + 1} </span>
                                <span
                                  className={`p-steps-title font-bold ${styles.textTransform}`}
                                >
                                  {a.label}
                                </span>
                              </span>
                            </p>
                          </li>
                        ))}
                        {/* <li
                        className="p-steps-item p-disabled"
                        role="tab"
                        aria-selected="false"
                        aria-expanded="false"
                      >
                        <a
                          href="#"
                          className="p-menuitem-link"
                          role="presentation"
                          tabindex="-1"
                        >
                          <span className="p-steps-number">2</span>
                          <span className="p-steps-title">Landlord Details</span>
                        </a>
                      </li>
                      <li
                        className="p-steps-item p-disabled"
                        role="tab"
                        aria-selected="false"
                        aria-expanded="false"
                      >
                        <a
                          href="#"
                          className="p-menuitem-link"
                          role="presentation"
                          tabindex="-1"
                        >
                          <span className="p-steps-number">3</span>
                          <span className="p-steps-title">Tenant Details</span>
                        </a>
                      </li>
                      <li
                        className="p-steps-item p-disabled"
                        role="tab"
                        aria-selected="false"
                        aria-expanded="false"
                      >
                        <a
                          href="#"
                          className="p-menuitem-link"
                          role="presentation"
                          tabindex="-1"
                        >
                          <span className="p-steps-number">4</span>
                          <span className="p-steps-title">Property Details</span>
                        </a>
                      </li>
                      <li
                        className="p-steps-item p-disabled"
                        role="tab"
                        aria-selected="false"
                        aria-expanded="false"
                      >
                        <a
                          href="#"
                          className="p-menuitem-link"
                          role="presentation"
                          tabindex="-1"
                        >
                          <span className="p-steps-number">5</span>
                          <span className="p-steps-title">Contract Details</span>
                        </a>
                      </li>
                      <li
                        className="p-steps-item p-disabled"
                        role="tab"
                        aria-selected="false"
                        aria-expanded="false"
                      >
                        <a
                          href="#"
                          className="p-menuitem-link"
                          role="presentation"
                          tabindex="-1"
                        >
                          <span className="p-steps-number">6</span>
                          <span className="p-steps-title">Item List</span>
                        </a>
                      </li> */}
                      </ul>
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    step !== items.length - 1 ? "col-8" : "col-12"
                  }`}
                >
                  <div className={styles.formbox}>
                    <div className={styles.formboxSpacing}>
                      <h3 className="text-center">Create New Agreement</h3>
                      <div className="">
                        <FormProvider {...methods}>
                          <form className="p-fluid">
                            {cases()}

                            <div className="flex">
                              {step !== 0 && (
                                <Button
                                  label="Previous"
                                  className="mt-2 mr-2"
                                  type="button"
                                  onClick={prevStep}
                                />
                              )}
                              {step === items.length - 1 ? (
                                <Button
                                  label="Submit"
                                  type="submit"
                                  className="mt-2"
                                  onClick={handleSubmit(onSubmit)}
                                />
                              ) : (
                                <Button
                                  label="Next"
                                  className="mt-2"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    nextStep();
                                  }}
                                />
                              )}
                            </div>
                          </form>
                        </FormProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};
