import React, { useState } from "react";
import style from "./ContactSection.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";

export const ContactSection = (props) => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState("");
  const defaultValues = {
    name: "",
    phone: "",
    city: "",
    service: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    try {
      await axios.post("https://mysro.in/post/enquiry", {
        name: e.name,
        phone: e.phone,
        city: e.city,
        service: e.service,
      });
      setFormData(e);
      setShowMessage(true);
      reset();
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.msg);
      }
    }
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  return (
    <div
      className={`section-pt section-pb position-relative ${style.formSectionBg}`}
    >
      <div className="container">
        <div className="grid">
          <div className={`md:col-6 ${style.pr8}`}>
            <h3 className={style.title}>Get in Touch</h3>
            <p>
              Any queries or concern that you need clarified? Our team at MySRO
              is here to help you.
            </p>
            <p>
              mySRO is comprised of the best-in-class advocates and real estate
              experts supported by our advanced technical team to provide you
              with quick, efficient and quality solutions.
            </p>
            <p>
              Currently we are providing our services across Tamilnadu. We will
              be expanding soon to the rest of India.
            </p>
          </div>
          <div className="md:col-6">
            <div className="contactForm">
              <Dialog
                visible={showMessage}
                onHide={() => setShowMessage(false)}
                position="top"
                footer={dialogFooter}
                showHeader={false}
                breakpoints={{ "960px": "80vw" }}
                style={{ width: "30vw" }}
              >
                <div className="flex justify-content-center flex-column pt-6 px-3">
                  <i
                    className="pi pi-check-circle"
                    style={{ fontSize: "5rem", color: "var(--green-500)" }}
                  ></i>
                  <h5>Enquiry sent Successful!</h5>
                  <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
                    Your enquiry is sent under name <b>{formData.name}</b>
                  </p>
                </div>
              </Dialog>

              <div className="">
                <div className={style.card}>
                  <p className="text-center">{message}</p>
                  <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <div className="grid">
                      <div className={`col-12 field ${style.topField}`}>
                        <span className={`p-float-label ${style.floatLabel}`}>
                          <Controller
                            name="name"
                            control={control}
                            rules={{ required: "Name is required." }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />
                          <label
                            htmlFor="name"
                            className={classNames({ "p-error": errors.name })}
                          >
                            Name*
                          </label>
                        </span>
                        {getFormErrorMessage("name")}
                      </div>
                      <div className={`col-12 field ${style.topField}`}>
                        <span className={`p-float-label ${style.floatLabel}`}>
                          <Controller
                            name="phone"
                            control={control}
                            rules={{
                              required: "Phone number is required.",
                              pattern: {
                                value: /^[0-9]{10}$/i,
                                message:
                                  "Invalid phone number address. E.g. 9000000000",
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                              />
                            )}
                          />
                          <label
                            htmlFor="phone"
                            className={classNames({
                              "p-error": !!errors.phone,
                            })}
                          >
                            Phone*
                          </label>
                        </span>
                        {getFormErrorMessage("phone")}
                      </div>
                      <div className={`col-12 field ${style.topField}`}>
                        <span className="">
                          <Controller
                            name="city"
                            control={control}
                            rules={{ required: "City is required." }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                {...field}
                                placeholder="Select City"
                                id={field.name}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                                className={style.Dropdown}
                                options={[
                                  "Alandur",
                                  "Ambattur",
                                  "Ambur",
                                  "Avadi",
                                  "Chennai",
                                  "Coimbatore",
                                  "Cuddalore",
                                  "Dindigul",
                                  "Erode",
                                  "Hosur",
                                  "Kancheepuram",
                                  "Karaikkudi",
                                  "Kumbakonam",
                                  "Kurichi",
                                  "Madavaram",
                                  "Madurai",
                                  "Nagapattinam",
                                  "Nagercoil",
                                  "Neyveli",
                                  "Pallavaram",
                                  "Pudukkottai",
                                  "Rajapalayam",
                                  "Salem",
                                  "Tambaram",
                                  "Thanjavur",
                                  "Thoothukkudi",
                                  "Tiruchirappalli",
                                  "Tirunelveli",
                                  "Tiruppur",
                                  "Tiruvannamalai",
                                  "Tiruvottiyur",
                                  "Vellore",
                                ]}
                              />
                            )}
                          />
                        </span>
                        {getFormErrorMessage("city")}
                      </div>
                      <div className={`col-12 field ${style.topField}`}>
                        <span className="">
                          <Controller
                            name="service"
                            control={control}
                            rules={{ required: "Service is required." }}
                            render={({ field, fieldState }) => (
                              <Dropdown
                                {...field}
                                id={field.name}
                                placeholder="Select Service type"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                                className={style.Dropdown}
                                options={[
                                  "Property Registration",
                                  "mySRO Property Report",
                                  "Rental Agreement",
                                  "Legal Consultation",
                                  "mySRO Valuation Report",
                                ]}
                              />
                            )}
                          />
                        </span>
                        {getFormErrorMessage("service")}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      label="Book for Free Consultation"
                      className={`mt-2 ${style.submitButton}`}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
