import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import "./Service.css";

export const Services = () => {
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>Services</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt relative">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="pb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="a">
        <div className="container">
          <div className="grid">
            <div className="col-5">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4">
              <div className="title-section">
                <h3 className="title pb-2">End-To-End Property Registration</h3>
                <p className="">
                  End-to-end property registration includes a tedious process of
                  registering a property from the initial stages to the final
                  stages of ownership transfer. It has various stages and
                  entities, including the property owner, the government
                  authorities, the legal system, and the registration agencies.
                </p>
                <p className="">
                  The following are the steps involved in an end-to-end property
                  registration process:
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Property
                  Verification is to ensure the space is legitimate, the land
                  related documents are authentic without any legal disputes or
                  pending litigation.
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Property Valuation
                  is to determine the market value of the property with the help
                  of government-approved valuator.
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Stamp Duty Payment
                  is the tax levied by the government on the transfer of
                  property ownership. The stamp duty rate varies from state to
                  state.
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Preparation of
                  Required Documents for property registration. It includes the
                  sale agreement, property deed, property tax receipts, and
                  other relevant documents.
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Property
                  Registration entails the submission of necessary documents
                  along with the stamp duty receipt to verify the documents and
                  complete the registration process.
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Ownership
                  Transfer, which is the final stage, is the transfer of
                  ownership. This requires the execution of a sale deed or a
                  gift deed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="b">
        <div className="container">
          <div className="grid">
            <div className="col-5 flex-order-1">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4 flex-order-0">
              <div className="title-section">
                <h3 className="title pb-2">Business Plan</h3>
                <p className="text-primary font-bold pb-2">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again <br className="d-none d-xl-block" />
                  is there occasionally circumstances occur in which toil and
                  pain can procure
                </p>
                <p className="pb-3">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again there anyone who loves or pursues or
                  desires to obtain voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Most confortable
                  Business Plan for your Business
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est qui
                  dolorem ipsum quia dolor sit amet consectetur
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>These cases are
                  perfectly simple and easy to distinguish
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="c">
        <div className="container">
          <div className="grid">
            <div className="col-5">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4">
              <div className="title-section">
                <h3 className="title pb-2">Business Plan</h3>
                <p className="text-primary font-bold pb-2">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again <br className="d-none d-xl-block" />
                  is there occasionally circumstances occur in which toil and
                  pain can procure
                </p>
                <p className="pb-3">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again there anyone who loves or pursues or
                  desires to obtain voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Most confortable
                  Business Plan for your Business
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est qui
                  dolorem ipsum quia dolor sit amet consectetur
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>These cases are
                  perfectly simple and easy to distinguish
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="d">
        <div className="container">
          <div className="grid">
            <div className="col-5 flex-order-1">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4 flex-order-0">
              <div className="title-section">
                <h3 className="title pb-2">Business Plan</h3>
                <p className="text-primary font-bold pb-2">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again <br className="d-none d-xl-block" />
                  is there occasionally circumstances occur in which toil and
                  pain can procure
                </p>
                <p className="pb-3">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again there anyone who loves or pursues or
                  desires to obtain voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Most confortable
                  Business Plan for your Business
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est qui
                  dolorem ipsum quia dolor sit amet consectetur
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>These cases are
                  perfectly simple and easy to distinguish
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="e">
        <div className="container">
          <div className="grid">
            <div className="col-5">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4">
              <div className="title-section">
                <h3 className="title pb-2">Business Plan</h3>
                <p className="text-primary font-bold pb-2">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again <br className="d-none d-xl-block" />
                  is there occasionally circumstances occur in which toil and
                  pain can procure
                </p>
                <p className="pb-3">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again there anyone who loves or pursues or
                  desires to obtain voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Most confortable
                  Business Plan for your Business
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est qui
                  dolorem ipsum quia dolor sit amet consectetur
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>These cases are
                  perfectly simple and easy to distinguish
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-pt relative" id="f">
        <div className="container">
          <div className="grid">
            <div className="col-5 flex-order-1">
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
            </div>
            <div className="col-7 pl-4 flex-order-0">
              <div className="title-section">
                <h3 className="title pb-2">Business Plan</h3>
                <p className="text-primary font-bold pb-2">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again <br className="d-none d-xl-block" />
                  is there occasionally circumstances occur in which toil and
                  pain can procure
                </p>
                <p className="pb-3">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again there anyone who loves or pursues or
                  desires to obtain voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit
                </p>
              </div>
              <div className="single-service-list">
                <p>
                  <i className="pi pi-angle-double-right"></i> Most confortable
                  Business Plan for your Business
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est qui
                  dolorem ipsum quia dolor sit amet consectetur
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>These cases are
                  perfectly simple and easy to distinguish
                </p>
                <p>
                  <i className="pi pi-angle-double-right"></i>Quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
