import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export const InnovationTechnology = () => {
  return (
    <>
      <Header />
      <div className="breadcrumb-area shadow dark bg-fixed text-center py-8 text-light">
        <div className="container">
          <div className="grid">
            <div className="col-md-6 text-left">
              <h1>Innovation and Technology</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-pt position-relative">
        <div className="container">
          <div className="grid">
            <div className="col-8 col-offset-2 text-center">
              <div className="title-section mb-3 text-center">
                <span className="sub-title">FEATURES</span>
                <h3 className="title">
                  We are <span className="text-primary">different</span>{" "}
                  because...
                </h3>
                <p className="mb-6">
                  Pleasure rationally encounter consequences that are extremely
                  painful. Nor again is there anyone who loves or pursues or
                  desires to obtain
                </p>
              </div>
              <img
                src="https://htmldemo.net/businex/businex/assets/img/service/01.jpg"
                alt="test"
              />
              <p className="mb-6">
                Pleasure rationally encounter consequences that are extremely
                painful. Nor again is there anyone who loves or pursues or
                desires to obtain Pleasure rationally encounter consequences
                that are extremely painful. Nor again is there anyone who loves
                or pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain Pleasure rationally encounter consequences that are
                extremely painful. Nor again is there anyone who loves or
                pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain Pleasure rationally encounter consequences that are
                extremely painful. Nor again is there anyone who loves or
                pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain
              </p>
              <p className="mb-6">
                Pleasure rationally encounter consequences that are extremely
                painful. Nor again is there anyone who loves or pursues or
                desires to obtain Pleasure rationally encounter consequences
                that are extremely painful. Nor again is there anyone who loves
                or pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain Pleasure rationally encounter consequences that are
                extremely painful. Nor again is there anyone who loves or
                pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain Pleasure rationally encounter consequences that are
                extremely painful. Nor again is there anyone who loves or
                pursues or desires to obtain Pleasure rationally encounter
                consequences that are extremely painful. Nor again is there
                anyone who loves or pursues or desires to obtain Pleasure
                rationally encounter consequences that are extremely painful.
                Nor again is there anyone who loves or pursues or desires to
                obtain
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
