import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import "./FormStyles.css";

export const FormUserDetail = ({ basicDetail, ...props }) => {
  const {
    control,
    formState: { errors },
    setValue,
    resetField,
  } = useFormContext();

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  useEffect(() => {
    setValue("city", props.city);
    setValue("state", props.state);
    setValue("stamp", 100);
  }, [props.city, props.state]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(basicDetail.iam);
    if (basicDetail.iam === "Landlord") {
      setValue("landlordDetails.0.name", basicDetail.name);
      setValue("landlordDetails.0.state", basicDetail.state);
      setValue("landlordDetails.0.city", basicDetail.city);
      setValue("landlordDetails.0.email", basicDetail.email);
      setValue("landlordDetails.0.mobile", basicDetail.mobile);
      resetField("tenantDetails");
    } else if (basicDetail.iam === "Tenant") {
      setValue("tenantDetails.0.name", basicDetail.name);
      setValue("tenantDetails.0.state", basicDetail.state);
      setValue("tenantDetails.0.city", basicDetail.city);
      setValue("tenantDetails.0.email", basicDetail.email);
      setValue("tenantDetails.0.mobile", basicDetail.mobile);
      resetField("landlordDetails");
    } else {
      resetField("landlordDetails");
      resetField("tenantDetails");
    }
    setLoading(false);
  }, [loading]);
  return (
    <>
      <div className="p-fluid pt-5 formInputSpace">
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Name is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="name">Full Name*</label>
          </span>
          {getFormErrorMessage("name")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="state"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  value={props.state}
                  disabled={true}
                />
              )}
            />
            <label htmlFor="state">State</label>
          </span>
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="city"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  value={props.city}
                  disabled={true}
                />
              )}
            />
            <label htmlFor="city">City</label>
          </span>
        </div>
        <div className="field">
          <span className="p-float-label p-input-icon-right">
            <i className="pi pi-envelope" />

            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address. E.g. example@email.com",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="email">Email*</label>
          </span>
          {getFormErrorMessage("email")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="mobile"
              control={control}
              rules={{ required: "Mobile number is required." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            <label htmlFor="mobile">Mobile*</label>
          </span>
          {getFormErrorMessage("mobile")}
        </div>
        <div className="field">
          <span className="p-float-label">
            <Controller
              name="stamp"
              control={control}
              defaultValue={100}
              render={({ field, fieldState }) => (
                <InputText
                  type="number"
                  id={field.name}
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                  disabled={true}
                  value={100}
                />
              )}
            />
            <label htmlFor="stamp">Stamp</label>
          </span>
        </div>
        <div className="field ">
          <label htmlFor="iam">I am a/an </label>
          <span className="">
            <Controller
              name="iam"
              control={control}
              rules={{ required: "Please select Entity Type" }}
              render={({ field, fieldState }) => (
                // <InputText
                //   type="number"
                //   id={field.name}
                //   {...field}
                //   className={classNames({
                //     "p-invalid": fieldState.invalid,
                //   })}
                //   value={100}
                // />
                <div className="flex whoIm">
                  <div className="flex align-items-center">
                    <input
                      type="radio"
                      id="landlord"
                      // inputId="landlord"
                      {...field}
                      value="Landlord"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setLoading(true);
                      }}
                      checked={field.value === "Landlord"}
                    />
                    <label htmlFor="landlord" className="ml-2">
                      Landlord
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <input
                      type="radio"
                      id="tenant"
                      // inputId="tenant"
                      {...field}
                      value="Tenant"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setLoading(true);
                      }}
                      checked={field.value === "Tenant"}
                    />
                    <label htmlFor="tenant" className="ml-2">
                      Tenant
                    </label>
                  </div>
                  <div className="flex align-items-center">
                    <input
                      type="radio"
                      id="agent"
                      // inputId="agent"
                      {...field}
                      value="Agent"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      checked={field.value === "Agent"}
                    />
                    <label htmlFor="agent" className="ml-2">
                      Agent
                    </label>
                  </div>
                </div>
              )}
            />
          </span>
        </div>
      </div>
    </>
  );
};
